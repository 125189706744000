import React from 'react';

import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    internalFormName: string;
};

const SelectCompaniesInfo: React.FC<OwnProps> = (props) => {
    const {internalFormName} = props;

    const changeFormValues = useChangeReduxFormValues(internalFormName);

    const handleClearSelection = () => {
        changeFormValues({
            [getName('isAllCompaniesSelected')]: null,
            [getName('selectedCompanies')]: null,
        });
    };

    return (
        <div className="mt20">
            <span className={styles.title}>The truck operates for alliance companies:</span>

            <span className={styles.description}>Select only from companies partnered with the truck owner</span>

            <div className="mt10 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap10">
                    <span>
                        All Alliance companies: <strong>58</strong>
                    </span>

                    <span className={styles.dot} />

                    <span>
                        Owner work with: <strong>0</strong>
                    </span>

                    <span className={styles.dot} />

                    <span>
                        Truck work with: <strong>0</strong>
                    </span>
                </div>

                <div className={styles.clear} onClick={handleClearSelection}>
                    Clear selection
                </div>
            </div>
        </div>
    );
};

export default SelectCompaniesInfo;
