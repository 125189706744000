import {useSelector} from 'react-redux';

import * as commonSelectors from 'store/reducers/commonData/selectors';

import * as selectors from 'pages/TrucksNearby/redux/selectors';

type UseTrucksNearbyState = {
    externalSearchParams: ReturnType<typeof selectors.getExternalSearchParams>;
    destinationPoint: ReturnType<typeof selectors.getDestinationPoint>;
    quoteTemplate: ReturnType<typeof selectors.generateQuoteTemplate>;
    allCarriers: ReturnType<typeof commonSelectors.getAllCarriers>;
    searchParams: ReturnType<typeof selectors.getSearchParams>;
    originPoint: ReturnType<typeof selectors.getOriginPoint>;
    expandedIDs: ReturnType<typeof selectors.getExpandedIDs>;
    pagination: ReturnType<typeof selectors.getPagination>;
    sorting: ReturnType<typeof selectors.getSorting>;
    trucks: ReturnType<typeof selectors.getTrucks>;
};

const useTrucksNearbyState = (): UseTrucksNearbyState => ({
    externalSearchParams: useSelector(selectors.getExternalSearchParams),
    destinationPoint: useSelector(selectors.getDestinationPoint),
    quoteTemplate: useSelector(selectors.generateQuoteTemplate),
    allCarriers: useSelector(commonSelectors.getAllCarriers),
    searchParams: useSelector(selectors.getSearchParams),
    originPoint: useSelector(selectors.getOriginPoint),
    expandedIDs: useSelector(selectors.getExpandedIDs),
    pagination: useSelector(selectors.getPagination),
    sorting: useSelector(selectors.getSorting),
    trucks: useSelector(selectors.getTrucks),
});

export default useTrucksNearbyState;
