import React from 'react';

import Truck from 'core/entities/Truck/types';

import WorkWithCompaniesTable from './components/WorkWithCompaniesTable';

import styles from './styles.module.scss';

type OwnProps = {
    truck: Truck;
};

const WorkWithCompaniesInfo: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    return (
        <div className="modal-body__main">
            <div className={styles.title}>
                The truck operates for alliance companies: <strong>{truck?.companies?.length || 0}</strong>
            </div>

            <WorkWithCompaniesTable truck={truck} />
        </div>
    );
};

export default WorkWithCompaniesInfo;
