import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Truck from 'core/entities/Truck/types';

import useTruckWorkWithCompaniesActions from 'pages/TrucksNearby/components/common/modals/WorkWithCompanies/hooks/useTruckWorkWithCompaniesActions';
import useTruckWorkWithCompaniesState from 'pages/TrucksNearby/components/common/modals/WorkWithCompanies/hooks/useTruckWorkWithCompaniesState';

import WorkWithCompaniesTableEmpty from './components/WorkWithCompaniesTableEmpty';
import WorkWithCompaniesTableRow from './components/WorkWithCompaniesTableRow';

import styles from './styles.module.scss';

type OwnProps = {
    truck: Truck;
};

const WorkWithCompaniesTable: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const {getCompaniesByOrderBy} = useTruckWorkWithCompaniesActions();
    const {orderBy, companies} = useTruckWorkWithCompaniesState();

    const iconClassNames = classNames('fa', {
        [`fa-sort-alpha-asc`]: !orderBy || orderBy === 'asc',
        [`fa-sort-alpha-desc`]: orderBy === 'desc',
    });

    const companyNameClassNames = classNames('user-select-none', {
        [styles.applied]: orderBy,
        [styles.pointer]: !isEmpty(companies),
    });

    const renderCompanies = (companies || []).map((company) => (
        <WorkWithCompaniesTableRow key={company.id} company={company} />
    ));

    return (
        <div className={classNames(styles.table, styles.scroll, 'standard-table-style')}>
            <table className="table mb0">
                <thead>
                    <tr>
                        <th>
                            <div className={companyNameClassNames} onClick={getCompaniesByOrderBy}>
                                <span className="mr5">COMPANY NAME</span>

                                {!isEmpty(companies) ? <i className={iconClassNames} /> : null}
                            </div>
                        </th>

                        <th>PREFIX</th>

                        <th>PHONE</th>

                        <th>PHYSICAL ADDRESS</th>
                    </tr>
                </thead>

                <tbody className="table-body-bordered">
                    {isEmpty(companies) ? <WorkWithCompaniesTableEmpty truck={truck} /> : renderCompanies}
                </tbody>
            </table>
        </div>
    );
};

export default WorkWithCompaniesTable;
