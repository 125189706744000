import axios, {CancelTokenSource} from 'axios';
import fromPairs from 'lodash/fromPairs';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import {initialize, reset} from 'redux-form';

import {AppThunk} from 'store';
import * as appActions from 'store/actions';
import adminActions from 'store/reducers/adminSettings/actionCreators';
import {getShowDeleted} from 'store/reducers/adminSettings/selectors';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';
import {getCurrentRegion} from 'store/selectors';

import {TRUCK_PREFERRED_LOADS} from 'core/entities/Truck/constants/truckPreferredLoads';
import Truck from 'core/entities/Truck/types';
import * as truckRequests from 'core/gateways/TruckApiGateway/requests';

import {getNewCancelTokenSource} from 'services/restapi';

import * as modalActions from 'components/ui/ModalProvider/actions';

import {TRUCK_LIST_FILTER_FORM_NAME} from 'pages/Trucks/constants';
import {characteristics as fields} from 'pages/Trucks/constants/fields';
import {TrucksListDepracatedResponse, TrucksListResponse} from 'pages/Trucks/types/api';
import {FilterSearchFormValues} from 'pages/Trucks/types/filter';
import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';

import countries from 'utils/data/countries';
import * as entityNames from 'utils/data/entityNames';
import {isEuropeRegion} from 'utils/data/region';
import {getTemperatureUnitByLabel} from 'utils/data/temperatureUnits';
import formatPaginationParams from 'utils/formatPaginationParams';
import {convertToInches} from 'utils/lengthConverter';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';
import {getTypeFieldNameFactory} from 'utils/typeScript';
import {convertToLbs} from 'utils/weightConverter';

import * as types from '../actionTypes';
import {transformFormDataToRequestBody} from '../mappers/status';

const getName = getTypeFieldNameFactory<FilterSearchFormValues>();

export function setSearchParams(searchParams = {}) {
    return {
        type: types.TRUCKS_SET_SEARCH_PARAMS,
        payload: {
            searchParams,
        },
    };
}

const formatSearchParams = (params) =>
    omitBy(
        {
            ...params,
            license_country: params.license_country && params.license_country.value,
            license_state: params.license_state && params.license_state.value,
            reserved_by: params.reserved_by && params.reserved_by.value,
            driver: params.driver && params.driver.value,
            owner: params.owner && params.owner.value,
            year: params.year && params.year.value,
            temperatureDegreesUnit:
                params.temperatureRangeMin || params.temperatureRangeMax
                    ? getTemperatureUnitByLabel(params.temperatureDegreesUnit)
                    : null,
            temperatureRangeMin: Number(params.temperatureRangeMin) || null,
            temperatureRangeMax: Number(params.temperatureRangeMax) || null,
        },
        isNull,
    );

export function toggleDrawerExpand(toggleData) {
    return {
        type: types.TOGGLE_DRAWER_EXPAND,
        payload: {toggleData},
    };
}

const transformCertificatesDataToRequestBody = (params: Partial<FilterSearchFormValues>) => {
    return fromPairs(params?.certificates?.split(',').map((certificate) => [certificate, true]));
};
const transformCrossBorderDataToRequestBody = (params: Partial<FilterSearchFormValues>) => {
    if (!params?.cross_border) {
        return undefined;
    }
    const countryNames = {};

    params?.cross_border?.split(',').forEach((countryCode) => {
        if (countries[countryCode]) {
            countryNames[toLower(countries[countryCode].name).replace(/\s+/g, '_')] = true;
        }
    });

    return countryNames;
};

const getTrucks = () => (dispatch, getState) => {
    const state = getState();

    try {
        let axiosSourceForFetchingTrucks: CancelTokenSource | null = null;
        const {searchParams, pagination} = state.trucks.list;
        const currentSortFrom = getCurrentSortFrom(state, 'trucks');
        const currentSortBy = getCurrentSortBy(state, 'trucks');
        const params = {
            sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {},
            ...formatPaginationParams(pagination),
            is_deleted: getShowDeleted(state, entityNames.ENTITY_NAME_TRUCKS),
        };

        if (!isEmpty(searchParams)) {
            if (axiosSourceForFetchingTrucks) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                axiosSourceForFetchingTrucks.cancel('fetching trucks was canceled by the user.');
                axiosSourceForFetchingTrucks = null;
            }

            // calculations for dimension units
            const dimsFieldsNames = [fields.valid_dims_height, fields.valid_dims_length, fields.valid_dims_width];
            const dimsObject = pick(formatSearchParams(searchParams), dimsFieldsNames);
            const dimsUnit = pick(formatSearchParams(searchParams), fields.dims_units)[fields.dims_units];
            const transformedDims = mapValues(dimsObject, (item) => convertToInches(dimsUnit, item));

            // calculations for payload units
            const payloadFieldsNames = [getName('payload'), getName('gross_weight')];
            const payloadObject = pick(formatSearchParams(searchParams), payloadFieldsNames);
            const payloadUnit = pick(formatSearchParams(searchParams), getName('payload_units'))[
                getName('payload_units')
            ];
            const crossBorder = transformCrossBorderDataToRequestBody(searchParams);
            const certificates = transformCertificatesDataToRequestBody(searchParams);
            const transformedPayload = mapValues(payloadObject, (item) => convertToLbs(payloadUnit, item));
            const commonEquipmentList = searchParams?.equipment?.split(',') || [];
            const basicEquipmentList = state.registry.truckBasicEquipment.map((elem) => elem.value);
            const additionalEquipmentList = state.registry.truckAdditionalEquipment.map((elem) => elem.value);
            const basicEquipmentFields = intersection(commonEquipmentList, basicEquipmentList);
            const additionalEquipmentFields = intersection(commonEquipmentList, additionalEquipmentList);
            const locationStates = searchParams.location_state?.map?.(({value}) => value)?.join?.() || '';
            const homeStates = searchParams.home_state?.map?.(({value}) => value)?.join?.() || '';

            // Truck and Driver Expiration
            const [truckExpDateFrom, truckExpDateTo] = searchParams.truckExpiration?.dateRange?.split?.(' - ') || [];
            const [driverExpDateFrom, driverExpDateTo] = searchParams.driverExpiration?.dateRange?.split?.(' - ') || [];
            const truckExpDateRange =
                truckExpDateFrom && truckExpDateTo ? {from: truckExpDateFrom, to: truckExpDateTo} : null;
            const driverExpDateRange =
                driverExpDateFrom && driverExpDateTo ? {from: driverExpDateFrom, to: driverExpDateTo} : null;
            const truckExpiration = searchParams.truckExpiration
                ? {...searchParams.truckExpiration, dateRange: truckExpDateRange}
                : null;
            const driverExpiration = searchParams.driverExpiration
                ? {...searchParams.driverExpiration, dateRange: driverExpDateRange}
                : null;

            const searchParamsWithTransformedMeasurements = {
                ...formatSearchParams(searchParams),
                ...transformedDims,
                ...transformedPayload,
                ...certificates,
                ...crossBorder,
                truckExpiration,
                driverExpiration,
                equipment: basicEquipmentFields.join(','),
                additional_equipment: additionalEquipmentFields.join(','),
                location_state: locationStates,
                home_state: homeStates,
            };

            const searchParamsWithOmittedUnitsOfMeasurements = omit(searchParamsWithTransformedMeasurements, [
                fields.dims_units,
                fields.dims_units,
            ]);

            return truckRequests.searchTrucks({...params, ...searchParamsWithOmittedUnitsOfMeasurements});
        }

        axiosSourceForFetchingTrucks = getNewCancelTokenSource();

        const {token: cancelToken} = axiosSourceForFetchingTrucks;

        return truckRequests.getTrucks(params, cancelToken);
    } catch (error) {
        console.error(error);
        throw new Error(error as any);
    }
};

export function receiveDispatcherNote(payload) {
    return {
        type: types.TRUCK_DISPATCHER_NOTE_UPDATED,
        payload,
    };
}

export const receiveTrucks = (response: TrucksListDepracatedResponse | TrucksListResponse) => {
    if ('pagination' in response.data && 'items' in response.data) {
        return {
            type: types.RECEIVE_TRUCKS,
            payload: {
                trucks: response.data.items,
                pagination: response.data.pagination,
            },
        };
    }

    return {
        type: types.RECEIVE_TRUCKS,
        payload: {
            trucks: response.data,
            pagination: parsePaginationHeaders(response.headers),
        },
    };
};

export const fetchTrucks = () => async (dispatch, getState) => {
    try {
        dispatch(appActions.showLoader());
        dispatch(appActions.startLoadingData());

        const response = await dispatch(getTrucks());

        dispatch(receiveTrucks(response));
        dispatch(appActions.hideLoader());
        dispatch(appActions.endLoadingData());
    } catch (err) {
        if (!axios.isCancel(err)) {
            dispatch(appActions.handleError(err));
            dispatch(appActions.hideLoader());
            dispatch(appActions.endLoadingData());
        }
    }
};

export function viewNotes(selectedTruck): AppThunk {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        dispatch(
            toggleDrawerExpand({
                drawerExpand: true,
                truck: selectedTruck,
            }),
        );
        dispatch(appActions.hideLoader());
    };
}

// export for unit tests
export function receiveStatusUpdate(payload) {
    return {
        type: types.TRUCK_STATUS_UPDATE,
        payload,
    };
}

export const updateStatus = (params: {truck: Truck; formData: UpdateStatusModalFormValues}): AppThunk => async (
    dispatch,
) => {
    const {truck, formData} = params;

    const requestBody = transformFormDataToRequestBody({formData});

    try {
        dispatch(appActions.showLoader());

        await truckRequests.updateTruckStatus({truckID: truck.id, requestBody});

        const {data} = await truckRequests.getTruckByNumber(truck.number);

        dispatch(receiveStatusUpdate(data));
        dispatch(modalActions.closeAll());
    } catch (error) {
        dispatch(modalActions.closeAll());
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function setCurrentPage(page) {
    return {
        type: types.TRUCKS_SET_CURRENT_PAGE,
        payload: page,
    };
}

export function setPagination(currentPage, perPage) {
    return {
        type: types.TRUCKS_SET_PAGINATION,
        payload: {currentPage, perPage},
    };
}

export function toggleTruckRow(truckID) {
    return {
        type: types.TRUCK_TOGGLE_ROW,
        payload: {
            truckID,
        },
    };
}

export function toggleAllTruckRows(rowsIds) {
    return {
        type: types.TRUCK_TOGGLE_ALL_ROWS,
        payload: {
            rowsIds,
        },
    };
}

export function setShowArchivedTrucks({showArchived}: {showArchived: boolean}) {
    return function (dispatch) {
        dispatch(setCurrentPage(1));
        dispatch(adminActions.setAdminSettings({trucks: {showDeleted: showArchived}}));
    };
}

export function clearState() {
    return {
        type: types.CLEAR_STATE,
    };
}

export const resetSearchForm = () => (dispatch, getState) => {
    const state = getState();
    const currentRegion = getCurrentRegion(state);
    const isEurope = isEuropeRegion(currentRegion);

    const initFormValues = {
        preferred_loads: keys(TRUCK_PREFERRED_LOADS).join(','),
        temperatureDegreesUnit: isEurope ? '°C' : '°F',
    };

    dispatch(reset(TRUCK_LIST_FILTER_FORM_NAME));

    dispatch(initialize(TRUCK_LIST_FILTER_FORM_NAME, initFormValues));
};
