import React from 'react';
import ReactSelect, {components} from 'react-select';
import classNames from 'classnames';
import head from 'lodash/head';
import {WrappedFieldProps} from 'redux-form';

import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import {Option as OptionType} from 'components/ui/Form/SingleSelect';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useClearReduxFormValue from 'hooks/reduxForm/useClearReduxFormValue';

import styles from './styles.module.scss';

const OptionWithIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.Option {...props}>
            <span>{label}</span>&nbsp;
            {icon ? <i className={`fa ${icon}`} aria-hidden="true" /> : null}
        </components.Option>
    );
};

const SingleValueWithIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.SingleValue {...props}>
            <span>{label}</span>&nbsp;
            {icon ? <i className={`fa ${icon}`} aria-hidden="true" /> : null}
        </components.SingleValue>
    );
};

type OwnProps = {
    externalOnChange?: (option: OptionType | null) => void;

    input: WrappedFieldProps['input'];
    isFirstOptionSelected?: boolean;
    meta: WrappedFieldProps['meta'];
    closeMenuOnSelect?: boolean;
    errorPlacement?: string;
    options?: OptionType[];
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
};

const Select: React.FC<OwnProps> = (props) => {
    const {
        isFirstOptionSelected,
        closeMenuOnSelect,
        externalOnChange,
        errorPlacement,
        isClearable,
        placeholder,
        isDisabled,
        options,
        input,
        meta,
    } = props;

    const changeValue = useChangeReduxFormValue(meta.form);
    const clearValue = useClearReduxFormValue(meta.form);

    const validationState = getValidationState(meta);

    const handleChange = (option: OptionType | null) => {
        if (externalOnChange) {
            externalOnChange(option);
            return;
        }

        if (!option && meta.initial) {
            changeValue(input.name, null);
            return;
        }

        if (!option) {
            changeValue(input.name, null);
            clearValue(input.name);
            return;
        }

        changeValue(input.name, option);
    };

    const wrapClassNames = classNames(styles.wrap, {
        [styles.error]: !validationState,
        [styles.disabled]: isDisabled,
    });

    const getSelectValue = () => {
        if (input.value) {
            return input.value;
        }

        if (isFirstOptionSelected) {
            return head(options);
        }

        return null;
    };

    return (
        <OverlayTriggerCustom className={wrapClassNames} inputMeta={meta} placement={errorPlacement}>
            <ReactSelect
                components={{Option: OptionWithIcon, SingleValue: SingleValueWithIcon}}
                closeMenuOnSelect={closeMenuOnSelect}
                isClearable={isClearable}
                placeholder={placeholder}
                value={getSelectValue()}
                onChange={handleChange}
                isDisabled={isDisabled}
                options={options}
            />
        </OverlayTriggerCustom>
    );
};

export default Select;
