import React, {FC} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextArea from 'components/ui/Form/TextArea';

import {validate} from '../../validation';

import 'components/common/Truck/modals/DispatcherNoteModal/style.scss';

type OwnProps = {
    onClose();
    isAddNoteDisabled?: boolean;
};

const Form: FC<OwnProps> = (props) => {
    const {handleSubmit, onClose, pristine, isAddNoteDisabled, submitting} = props as InjectedFormProps & OwnProps;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main note-container">
                <Field
                    rows={5}
                    name="text"
                    type="text"
                    component={TextArea}
                    disabled={isAddNoteDisabled}
                    placeholder="type a note about current driver"
                />
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="green" disabled={pristine || submitting || isAddNoteDisabled}>
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    validate,
})(Form);
