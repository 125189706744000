import isEmpty from 'lodash/isEmpty';

import useSelectCompaniesActions from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesActions';
import useSelectCompaniesState from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import {SELECT_COMPANIES_FORM_NAME} from 'pages/Owners/constants';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

const useSearchResult = () => {
    const changeFormValue = useChangeReduxFormValue(SELECT_COMPANIES_FORM_NAME);
    const {searchParams, searchMatchCount} = useSelectCompaniesState();
    const {getCompaniesByFilters} = useSelectCompaniesActions();

    if (isEmpty(searchParams)) {
        return {filters: [], totalCount: 0};
    }

    const formFields = {
        [getName('companyName')]: ({fieldName, fieldValue}) => {
            return {
                onClear: () => {
                    const newFieldValue = '';

                    const onPostSuccessFunc = () => {
                        changeFormValue(fieldName, newFieldValue);
                    };

                    getCompaniesByFilters({filters: {...searchParams, [fieldName]: newFieldValue}, onPostSuccessFunc});
                },
                label: 'company name',
                value: fieldValue,
            };
        },
    };

    const filters = Object.entries(searchParams).map(([fieldName, fieldValue]): any => {
        return formFields[fieldName] ? formFields[fieldName]({fieldName, fieldValue}) : null;
    });

    return {
        filters: filters.filter(Boolean).flat(),
        totalCount: searchMatchCount,
    };
};

export default useSearchResult;
