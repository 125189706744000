import {push} from 'connected-react-router';
import values from 'lodash/values';

import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';
import {getStopsInfo} from 'core/entities/Load/modules/loadStop';
import Load from 'core/entities/Load/types';
import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import * as truckTypes from 'core/entities/Truck/constants/truckTypes';

import {TrucksNearbySearchFormValues} from 'pages/TrucksNearby/types/formTypes';

import * as entityTypes from 'utils/data/entityNames';
import {TRUCKS_NEAR_BY} from 'utils/data/pages';
import {changeDate} from 'utils/dateTime';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {trucksNearbyActionCreators} from '../list';

const getName = getTypeFieldNameFactory<TrucksNearbySearchFormValues>();

const getSearchParamsFromLoad = (load: Load) => {
    if (!load || !load.stops) {
        return null;
    }

    const loadStop = getStopsInfo(load);
    const firstPickup = loadStop.firstPickupStop;
    const lastDelivery = loadStop.lastDeliveryStop;

    const isPickupDeliveryCorrect = ({facility, dateStart}) =>
        facility && composeFacilityCityLine(facility) && facility.latitude && facility.longitude && dateStart;

    if (!isPickupDeliveryCorrect(firstPickup) || !isPickupDeliveryCorrect(lastDelivery)) {
        return null;
    }

    const {facility: firstPickupFacility, dateStart: firstPickupDate} = firstPickup;
    const {facility: lastDeliveryFacility} = lastDelivery;

    const statusDateFrom = changeDate(firstPickupDate, {operation: 'decrease', value: 1, valueType: 'days'})
        .dateInServerFormat;
    const statusDateTo = changeDate(firstPickupDate, {operation: 'increase', value: 3, valueType: 'days'})
        .dateInServerFormat;

    const firstPickupCityLine = composeFacilityCityLine(firstPickupFacility);
    const lastDeliveryCityLine = composeFacilityCityLine(lastDeliveryFacility);

    return {
        [getName('status')]: truckStatuses.TRUCK_STATUS_ITEMS_FOR_TRUCKS_NEARBY.map((status) => status.value).join(','),
        [getName('type')]: values(truckTypes.GENERAL_TRUCK_TYPES).join(','),
        [getName('destinationPoint')]: lastDeliveryCityLine,
        [getName('originPoint')]: firstPickupCityLine,
        [getName('statusDateFrom')]: statusDateFrom,
        [getName('statusDateTo')]: statusDateTo,
        [getName('radius')]: 200,
    };
};

export const openTruckNearbyPageWithExternalSearchParams = (params: {entity; type}) => (dispatch) => {
    const {entity, type} = params;

    if (type === entityTypes.ENTITY_NAME_LOAD) {
        const externalSearchParams = getSearchParamsFromLoad(entity);

        dispatch(trucksNearbyActionCreators.setExternalSearchParams({externalSearchParams}));
    }

    dispatch(push(`/${TRUCKS_NEAR_BY}`));
};
