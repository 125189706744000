import React from 'react';
import {Modal} from 'react-bootstrap';

import useModalActions from 'pages/TrucksNearby/hooks/useModalActions';
import useTrucksNearbyState from 'pages/TrucksNearby/hooks/useTrucksNearbyState';

import Form from './components/Form';

import {createInitFormValues} from './utils';

const SEND_QUOTE_FORM_NAME = 'send-quote-form';

type OwnProps = {
    data: {
        phoneListData: {label: string; value: string}[];
    };
};

const SendQuote: React.FC<OwnProps> = (props) => {
    const {data} = props;

    const {sendQuoteToUsers, closeModal} = useModalActions();
    const {quoteTemplate} = useTrucksNearbyState();

    const initialValues = createInitFormValues(data, quoteTemplate);

    const handleSubmit = (formData) => {
        sendQuoteToUsers({formData});
    };

    return (
        <Modal show={true} onHide={closeModal} dialogClassName="standard-form-modal modal-sm" backdrop="static">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>New Quote</Modal.Title>

                    <button onClick={closeModal} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Form
                    initialValues={initialValues}
                    form={SEND_QUOTE_FORM_NAME}
                    data={data.phoneListData}
                    onSubmit={handleSubmit}
                />
            </Modal.Body>
        </Modal>
    );
};

export default SendQuote;
