import React, {FC} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import Button from 'components/ui/Buttons/Button';
import {DispatcherLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';

import GeneralNote from 'types/GeneralNote';

import styles from './notes.module.scss';

const ADD_TO_NOTES_FORM_NAME = 'add-notes-modal-form';

type OwnProps = {
    data: {
        title: string;
        entity: any;
        addNoteDisabled: boolean;
    };
    handlers: {onSubmit()};
    handleSubmit(values: any);
    notes: GeneralNote[];
    noteField?: string;
    onClose();
    selectors: {
        [funcName: string]: Function;
    };
    values: {text: string};
    form: string;
};

const Form: FC<OwnProps> = (props) => {
    const {
        data: {title, entity, addNoteDisabled},
        handlers: {onSubmit},
        handleSubmit,
        notes = [],
        noteField,
        onClose,
    } = props;

    const isCurrentEntityArchived = entity?.is_deleted;

    const sortByDateTime = (items) => orderBy(items, (noteA) => noteA.post_date, ['desc']);

    return (
        <div className={`${styles.modal} modal-content`}>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>{title}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="modal-body__notes">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-body__main">
                            <div className="modal-body__nodes-list">
                                {sortByDateTime(notes).map((note, index) => {
                                    if (!note.text) {
                                        return null;
                                    }

                                    const dispatcherLink = note.dispatcher ? (
                                        <DispatcherLink
                                            dispatcher={note.dispatcher}
                                            content={getDispatcherName(note.dispatcher)}
                                            className="main-link"
                                        />
                                    ) : null;

                                    const timeOfCreate =
                                        (note.post_date && createDate(note.post_date).fullDate) || null;
                                    const isImportant = note?.is_important;

                                    return (
                                        <div
                                            key={index}
                                            className={classNames('notes-item', {
                                                'notes-item__important': isImportant,
                                            })}
                                        >
                                            <div className="header">
                                                <h4>{dispatcherLink}</h4>
                                                <div className="data-info">
                                                    <span>{timeOfCreate}</span>
                                                </div>
                                            </div>
                                            <p>{note.text}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="add-note-field">
                                <label className="form-label">
                                    <strong>Add Note:</strong>
                                </label>
                                <Field
                                    disabled={isCurrentEntityArchived || addNoteDisabled}
                                    name="text"
                                    component="textarea"
                                    placeholder="type a note here"
                                    className="form-control"
                                    rows="2"
                                />
                            </div>
                        </div>

                        <div className="modal-body__bottom">
                            <Button onClick={onClose}>Cancel</Button>
                            <Button colorTheme="blue" type="submit" disabled={!noteField || addNoteDisabled}>
                                add
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </div>
    );
};

const selector = formValueSelector(ADD_TO_NOTES_FORM_NAME);

export default connect<{}, {}, OwnProps>((state, props) => ({
    notes: props.selectors.notesSelector(state),
    noteField: selector(state, 'text'),
}))(
    reduxForm<{}, OwnProps>({
        form: ADD_TO_NOTES_FORM_NAME,
        onSubmitSuccess: (result, dispatch, props) => {
            dispatch(change(props.form, 'text', ''));
        },
        validate: (values: {text: string}) => {
            const errors: {text?: string} = {};
            if (!values.text) {
                errors.text = 'Required';
            }
            return errors;
        },
    })(Form),
);
