import Owner from 'core/entities/Owner/types';

import Pagination from 'types/Pagination';

export const OWNER_RECEIVED = 'OWNER_RECEIVED';
export const OWNER_ARCHIVED = 'OWNERS_COMPONENT_OWNER_ARCHIVED';
export const OWNER_RESTORED = 'OWNERS_COMPONENT_OWNER_RESTORED';
export const DISPATCHERS_RECEIVED = 'OWNERS_CONTAINER_DISPATCHERS_RECEIVED';
export const OWNER_GENERAL_NOTE_RECEIVED = 'OWNER_GENERAL_NOTE_RECEIVED';
export const OWNER_GENERAL_NOTE_DELETED = 'OWNER_GENERAL_NOTE_DELETED';
export const CLEAR_STATE = 'OWNERS_CONTAINER_CLEAR_STATE';
export const FORM_TAB_CHANGE = 'OWNERS_CONTAINER_FORM_TAB_CHANGE';
export const TOGGLE_RESET_FORM_MODAL = 'OWNERS_CONTAINER_TOGGLE_RESET_FORM_MODAL';
export const TOGGLE_CREATE_MODAL = 'OWNERS_CONTAINER_TOGGLE_CREATE_MODAL';
export const TOGGLE_UPDATE_MODAL = 'OWNERS_CONTAINER_TOGGLE_UPDATE_MODAL';
export const SET_OWNER_FIELDS_FOR_DRIVER = 'OWNERS_CONTAINER_SET_OWNER_FIELDS_FOR_DRIVER';
export const OWNERS_RECEIVED = 'OWNERS_COMPONENT_OWNERS_DATA_RECEIVED';
export const OWNERS_SET_SEARCH_PARAMS = 'OWNERS_COMPONENT_SET_SEARCH_PARAMS';
export const OWNERS_SET_PAGINATION = 'OWNERS_COMPONENT_SET_PAGINATION';
export const OWNERS_SET_CURRENT_PAGE = 'OWNERS_COMPONENT_SET_CURRENT_PAGE_COUNT';
export const OWNER_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED = 'OWNER_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED';
export const OWNER_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED = 'OWNER_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED';
export const OWNER_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED = 'OWNER_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED';

type Action<T> = {
    type: string;
    payload?: T;
};
export type ReceiveOwners = Action<{
    data: Owner[];
    pagination: Pagination;
}>;

export type ArchivedOwner = Action<{
    owner: Owner;
}>;

export type SetSearchParams = Action<{
    [key: string]: any;
}>;

export type SetCurrentPage = Action<number>;
export type SetPagination = Action<{
    currentPage: number;
    perPage: number;
}>;
