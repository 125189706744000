import {getEntityFieldName} from 'core/entities/Truck/types';

export const general = {
    number: getEntityFieldName('number'),
    crm_id: getEntityFieldName('crm_id'),
    status: getEntityFieldName('status'),
    status_date: getEntityFieldName('status_date'),

    responsible: getEntityFieldName('responsible'),
    owner: getEntityFieldName('owner'),
    firstDriver: 'firstDriver',
    secondDriver: 'secondDriver',
    owner_updates: getEntityFieldName('owner_updates'),
    first_driver_updates: getEntityFieldName('first_driver_updates'),
    second_driver_updates: getEntityFieldName('second_driver_updates'),

    hired_by: getEntityFieldName('hired_by'),
    hired_date: getEntityFieldName('hired_date'),
    newGeneralNote: 'newGeneralNote',
    status_latitude: getEntityFieldName('status_latitude'),
    status_longitude: getEntityFieldName('status_longitude'),
    status_city_line: getEntityFieldName('status_city_line'),
    last_latitude: getEntityFieldName('last_latitude'),
    last_longitude: getEntityFieldName('last_longitude'),
    last_city_line: getEntityFieldName('last_city_line'),
    updated_at: getEntityFieldName('updated_at'),
};

export const characteristics = {
    type: getEntityFieldName('type'),
    trailer_type: getEntityFieldName('trailer_type'),
    preferred_loads: getEntityFieldName('preferred_loads'),
    company_signs: getEntityFieldName('company_signs'),
    payload: getEntityFieldName('payload'),
    payload_units: getEntityFieldName('payload_units'),
    gross_weight: getEntityFieldName('gross_weight'),
    gross_weight_units: getEntityFieldName('gross_weight_units'),
    dims_units: getEntityFieldName('dims_units'),
    make: getEntityFieldName('make'),
    model: getEntityFieldName('model'),
    year: getEntityFieldName('year'),
    color: getEntityFieldName('color'),

    equipment: getEntityFieldName('equipment'),
    additional_equipment: getEntityFieldName('additional_equipment'),

    inside_dims_length: getEntityFieldName('inside_dims_length'),
    inside_dims_width: getEntityFieldName('inside_dims_width'),
    inside_dims_height: getEntityFieldName('inside_dims_height'),
    door_dims_width: getEntityFieldName('door_dims_width'),
    door_dims_height: getEntityFieldName('door_dims_height'),
    valid_dims_length: getEntityFieldName('valid_dims_length'),
    valid_dims_width: getEntityFieldName('valid_dims_width'),
    valid_dims_height: getEntityFieldName('valid_dims_height'),
    door_type: getEntityFieldName('door_type'),

    vincode: getEntityFieldName('vincode'),
    license_plate: getEntityFieldName('license_plate'),
    license_state: getEntityFieldName('license_state'),
    license_country: getEntityFieldName('license_country'),
    registration_expiration: getEntityFieldName('registration_expiration'),
    insurance_expiration: getEntityFieldName('insurance_expiration'),
    is_deleted: getEntityFieldName('is_deleted'),
    location_same_from: getEntityFieldName('location_same_from'),
    degreesUnit: 'degreesUnit',
    rangeMin: 'rangeMin',
    rangeMax: 'rangeMax',
    workWithCompanies: 'workWithCompanies',
};

export default {
    general,
    characteristics,
};
