import React from 'react';

import {getPreferredLoadsOptions} from 'components/common/Truck/TruckPreferredLoads/utils';
import SingleSelect from 'components/ui/Form/SingleSelect';

interface OwnProps {
    name: string;
    isDisabled?: boolean;
}

const TruckPreferredLoadsSelect: React.FC<OwnProps> = (props) => {
    const {name, isDisabled} = props;

    const truckPreferredLoadItems = getPreferredLoadsOptions();

    return (
        <SingleSelect
            name={name}
            isClearable={false}
            label="Preferred Load"
            isDisabled={isDisabled}
            options={truckPreferredLoadItems}
            placeholder="choose truck preferred Load"
        />
    );
};

export default TruckPreferredLoadsSelect;
