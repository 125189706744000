import {useDispatch, useSelector} from 'react-redux';

import Truck from 'core/entities/Truck/types';

import {closeModal} from 'components/ui/ModalProvider/actions';
import {getCurrentModalID} from 'components/ui/ModalProvider/selectors';

import * as modalActions from 'pages/TrucksNearby/redux/actions/modals';

type UseModalActions = {
    openWorkWithCompaniesModal: (params: {truck: Truck}) => void;
    sendQuoteToUsers: (params: {formData}) => void;
    openSendQuoteModal: () => void;
    closeModal: () => void;
};

const useModalActions = (): UseModalActions => {
    const currentModalID = useSelector(getCurrentModalID);
    const dispatch = useDispatch();

    return {
        openWorkWithCompaniesModal: (params) => dispatch(modalActions.openWorkWithCompaniesModal(params)),
        sendQuoteToUsers: (params: {formData}) => dispatch(modalActions.sendQuoteToUsers(params)),
        openSendQuoteModal: () => dispatch(modalActions.openSendQuoteModal()),
        closeModal: () => dispatch(closeModal(currentModalID)),
    };
};

export default useModalActions;
