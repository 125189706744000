import * as tabs from '../components/Forms/Tabs/tabConfig';

export const NEW_FILES_PATH_PREFIX = 'new_files';
export const EXISTING_FILES_PATH_PREFIX = 'files';

export const ATTACHMENT_TYPES = {
    avatar: 'avatar',
    generalFiles: 'general_files',
    newGeneralFiles: 'new_general_files',
};

export const filePaths = [
    `${NEW_FILES_PATH_PREFIX}.${ATTACHMENT_TYPES.avatar}`,
    `${NEW_FILES_PATH_PREFIX}.${ATTACHMENT_TYPES.generalFiles}`,
    `${EXISTING_FILES_PATH_PREFIX}.${ATTACHMENT_TYPES.avatar}`,
    `${EXISTING_FILES_PATH_PREFIX}.${ATTACHMENT_TYPES.generalFiles}`,
];

export const SEARCH_FILTER_FORM_NAME = 'owners_search_form';

export const ssnNumberMask = [
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    '-',
    ' ',
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    '-',
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
];

export const einNumberMask = [
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    '-',
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
    ' ',
    /[0-9]/,
];

export const stepsInitialState = [
    {
        tabFormName: tabs.GENERAL.section,
        title: tabs.GENERAL.createTitle,
        step: 1,
        disabled: false,
        completed: false,
        active: true,
        valid: false,
    },
    {
        tabFormName: tabs.CONTACT.section,
        title: tabs.CONTACT.createTitle,
        step: 2,
        disabled: true,
        completed: false,
        active: false,
        valid: false,
    },
];

export const SELECT_COMPANIES_FORM_NAME = 'select-companies-form';
