import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import {WrappedFieldProps} from 'redux-form';

import TextInput from 'components/ui/Form/TextInput';

import styles from './styles.module.scss';

const DELAY = 500;

type AdditionalOptions = {headerText: string; list: Option[]};

type Option = {label: string; value: string};

type OwnProps = {
    fetchData: (value: string) => Option[];

    additionalOptions?: AdditionalOptions;
    customHeader?: JSX.Element;
    isHighlight?: boolean;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    label?: string;
    delay?: number;
};

const TextInputWithAutoCompleteDropdown: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {
        additionalOptions,
        delay = DELAY,
        customHeader,
        placeholder,
        isHighlight,
        fetchData,
        maxLength,
        disabled,
        input,
        label,
        meta,
    } = props;

    const [options, setOptions] = React.useState<Option[]>([]);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>();

    const highlightText = (text: string): JSX.Element => {
        const textLowerCase = text.toLowerCase();
        const valueLowerCase = input.value.toLowerCase();
        const indexOfValue = textLowerCase.indexOf(valueLowerCase);

        if (indexOfValue === -1) {
            return <>{text}</>;
        }

        const textEndIndex = valueLowerCase.length + indexOfValue;
        const textFirstPart = text.substring(0, indexOfValue);
        const highlightedPart = text.substring(indexOfValue, textEndIndex);
        const textLastPart = text.substring(textEndIndex, textLowerCase.length);

        return (
            <div>
                {textFirstPart}
                <strong>{highlightedPart}</strong>
                {textLastPart}
            </div>
        );
    };

    const fetchHandler = async (): Promise<void> => {
        const data = await fetchData(input.value);

        setOptions(data);
        timeoutRef.current = null;
    };

    const cancelFetch = (): void => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    React.useEffect(() => {
        cancelFetch();

        if (!input.value) {
            setOptions([]);
            return;
        }

        timeoutRef.current = setTimeout(fetchHandler, delay);

        return cancelFetch;
    }, [input.value]);

    const getItemValue = (item: Option) => String(item.value);

    const onSelectHandler = (_, option: Option): void => {
        input.onChange(option.value);
    };

    const renderItem = (item: Option): JSX.Element => {
        return (
            <div onClick={() => onSelectHandler(null, item)} className={styles.option} key={item.value}>
                {isHighlight ? highlightText(item.label) : item.label}
            </div>
        );
    };

    const renderHeader = (text?: string) => (
        <div className={styles.header}>
            <strong>{text || 'Similar options:'}</strong>
        </div>
    );

    const renderMenu = (items: React.ReactNode[]): JSX.Element => {
        if (!items?.length && additionalOptions?.list?.length) {
            return (
                <div className={styles.menu}>
                    {renderHeader(additionalOptions.headerText)}
                    <div>
                        {additionalOptions.list.map((additionalItem) => {
                            return renderItem(additionalItem);
                        })}
                    </div>
                </div>
            );
        }

        if (items?.length) {
            return (
                <div className={styles.menu}>
                    {customHeader || renderHeader()}
                    <div>{items}</div>
                </div>
            );
        }

        return <div />;
    };

    const inputProps = {
        keyDownWithoutCheckForRepeat: true,
        errorTooltipPlacement: 'bottom',
        onFocus: input.onFocus,
        onBlur: input.onBlur,
        placeholder,
        maxLength,
        disabled,
        input,
        label,
        meta,
    };

    return (
        <ReactAutocomplete
            wrapperProps={{className: 'relative w100p'}}
            inputProps={inputProps}
            renderInput={TextInput}
            value={input.value}
            items={options}
            // handlers
            getItemValue={getItemValue}
            onSelect={onSelectHandler}
            onChange={input.onChange}
            renderMenu={renderMenu}
            renderItem={renderItem}
        />
    );
};

export default TextInputWithAutoCompleteDropdown;
