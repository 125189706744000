import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';

import {FactoringCompaniesReducerSearchParams} from 'pages/FactoringCompanies/types/common';
import {SearchFilterFormValues} from 'pages/FactoringCompanies/types/formTypes';

const removeEmptyObjValues = (obj) => {
    const entries = Object.entries(obj);

    const removedNull = entries.filter(([_, value]) => value !== null);
    const removedUndefined = removedNull.filter(([_, value]) => value !== undefined);

    return Object.fromEntries(removedUndefined);
};

export const transformReducerSearchParamsToRequestBody = (params: {
    searchParams: FactoringCompaniesReducerSearchParams;
}) => {
    const {searchParams} = params;

    const searchParamsBeforeClearing = {
        id: isNumber(searchParams?.companyID) ? searchParams.companyID : null,
        country: searchParams?.location?.country || null,
        companyName: searchParams?.companyName || null,
        state: searchParams?.location?.state || null,
        city: searchParams?.location?.city || null,
        zip: searchParams?.location?.zip || null,
        email: searchParams?.email || null,
        phone: searchParams?.phone || null,
    };

    return removeEmptyObjValues(searchParamsBeforeClearing);
};

export const transformFiltersFormDataToReducer = (params: {
    filters: Partial<SearchFilterFormValues>;
}): FactoringCompaniesReducerSearchParams => {
    const {filters} = params;

    const clearedLocation = removeEmptyObjValues({
        country: filters.location?.country || undefined,
        state: filters.location?.state || undefined,
        city: filters.location?.city || undefined,
        zip: filters.location?.zip || undefined,
    });

    const searchParams: FactoringCompaniesReducerSearchParams = {
        companyID: filters.companyID ? toNumber(filters.companyID) : undefined,
        location: isEmpty(clearedLocation) ? undefined : clearedLocation,
        companyName: filters.companyName || undefined,
        email: filters.email || undefined,
        phone: filters.phone || undefined,
    };

    return removeEmptyObjValues(searchParams);
};

export const transformFiltersFormDataToRequestBody = (params: {filters: Partial<SearchFilterFormValues>}) => {
    const {filters} = params;

    const searchParamsBeforeClearing = {
        id: filters?.companyID ? toNumber(filters?.companyID) : null,
        country: filters?.location?.country || null,
        companyName: filters?.companyName || null,
        state: filters?.location?.state || null,
        city: filters?.location?.city || null,
        zip: filters?.location?.zip || null,
        email: filters?.email || null,
        phone: filters?.phone || null,
    };

    return removeEmptyObjValues(searchParamsBeforeClearing);
};
