import {useSelector} from 'react-redux';

import * as selectors from 'store/reducers/appSettings/selectors';

const useGetDomainSettings = () => ({
    companyShortName: useSelector(selectors.getCompanyShortTitle),
    companyFullName: useSelector(selectors.getCompanyFullTitle),
    isSendQuote: useSelector(selectors.getSendQuote),
    urlLogo: useSelector(selectors.getDomainLogo),
});

export default useGetDomainSettings;
