import {useDispatch} from 'react-redux';

import Truck from 'core/entities/Truck/types';

import * as actions from 'pages/TrucksNearby/redux/actions/workWithCompanies';

type UseTruckWorkWithCompaniesActions = {
    initWorkWithCompanies: (params: {truck: Truck}) => void;
    clearWorkWithCompaniesState: () => void;
    getCompaniesByOrderBy: () => void;
};

const useTruckWorkWithCompaniesActions = (): UseTruckWorkWithCompaniesActions => {
    const dispatch = useDispatch();

    return {
        initWorkWithCompanies: (params) => dispatch(actions.initWorkWithCompanies(params)),
        clearWorkWithCompaniesState: () => dispatch(actions.clearWorkWithCompaniesState()),
        getCompaniesByOrderBy: () => dispatch(actions.getCompaniesByOrderBy()),
    };
};

export default useTruckWorkWithCompaniesActions;
