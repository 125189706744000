import isEmpty from 'lodash/isEmpty';

import * as websocketsTypes from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import * as types from 'pages/Trucks/redux/actionTypes';
import {CurrentTruck, UpdateFormTab} from 'pages/Trucks/types';

import Pagination from 'types/Pagination';

import * as mappers from '../mappers';

interface State {
    companiesTab: {
        items: CurrentTruck['companies'];
        orderBy: 'asc' | 'desc' | null;
        pagination: Pagination;
    };
    truckData?: CurrentTruck;
    update: {
        activeTab?: UpdateFormTab;
    };
}

const defaultState: State = {
    companiesTab: {
        items: [],
        orderBy: null,
        pagination: {totalItemsCount: 0, currentPage: 1, pagesCount: 1, perPage: 10},
    },
    truckData: undefined,
    update: {activeTab: 'general'},
};

export default function (state: State = defaultState, action): State {
    switch (action.type) {
        case types.TRUCK_RECEIVED: {
            return mappers.handleReducerByTruckReceived({state, truckData: action.payload.truckData});
        }

        case types.TRUCK_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED: {
            const {followingPage} = action.payload;

            return mappers.handleReducerByTruckCompaniesTabItemsByPageReceived({state, followingPage});
        }

        case types.TRUCK_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED: {
            const {perPage} = action.payload;

            return mappers.handleReducerByTruckCompaniesTabItemsByPerPageReceived({state, perPage});
        }

        case types.TRUCK_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED: {
            return mappers.handleReducerByTruckCompaniesTabItemsOrderByReceived({state});
        }

        case types.TRUCK_ARCHIVED: {
            const {truck: archivedTruck} = action.payload;
            if (!state.truckData) {
                return state;
            }
            return {
                ...state,
                truckData: {...state.truckData, ...archivedTruck},
            };
        }

        case types.TRUCK_NOTE_ADDED: {
            if (!state.truckData) {
                return state;
            }

            const newNote = action.payload;
            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    truck_notes: [newNote, ...state.truckData.truck_notes],
                },
            };
        }
        case types.TRUCK_NOTE_DELETED: {
            if (!state.truckData) {
                return state;
            }

            const {id: deletedNoteId} = action.payload;
            const {truck_notes} = state.truckData;
            const filteredNotes = truck_notes.filter((note) => note.id !== deletedNoteId);

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    truck_notes: filteredNotes,
                },
            };
        }

        case types.TRUCK_DISPATCHER_NOTE_UPDATED: {
            const updatedTruck = action.payload;
            // for case when we have update notes from another page
            if (isEmpty(state.truckData)) {
                return state;
            }
            const {note} = updatedTruck;

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...note,
                },
            };
        }

        case types.TRUCK_STATE_CLEAR:
            return defaultState;

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED: {
            if (!state.truckData || action.payload.truckId !== state.truckData.id) {
                return state;
            }

            return {
                ...state,
                truckData: {...state.truckData, reserve: action.payload},
            };
        }

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED: {
            if (!state.truckData || action.payload !== state.truckData.id) {
                return state;
            }

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    reserve: null,
                },
            };
        }

        case websocketsTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            if (!state.truckData || action.payload.id !== state.truckData.id) {
                return state;
            }
            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...action.payload,
                    dispatcher_note: action.payload.dispatcher_note || null,
                },
            };
        }

        case types.TRUCK_UPDATE_SET_ACTIVE_TAB: {
            return {
                ...state,
                update: {
                    ...state.update,
                    activeTab: action.payload,
                },
            };
        }

        case types.TRUCK_UPDATE_RESET_TAB: {
            return {...state, update: {...state.update, activeTab: 'general'}};
        }

        default:
            return state;
    }
}
