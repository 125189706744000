import React from 'react';

export const highlightSubstring = (text: string, substring: string) => {
    if (!substring) {
        return text;
    }

    const parts = text.split(new RegExp(`(${substring})`, 'gi'));

    return parts.map((part, index) =>
        part === substring ? <strong key={index}>{part}</strong> : <React.Fragment key={index}>{part}</React.Fragment>,
    );
};
