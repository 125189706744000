import React from 'react';
import {Field} from 'redux-form';

import Select from './components/Select';

export type Option = {label: string; value: unknown; icon?: string};

type OwnProps = {
    externalOnChange?: (option: Option | null) => void;

    isFirstOptionSelected?: boolean;
    label?: string | JSX.Element;
    closeMenuOnSelect?: boolean;
    errorPlacement?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    options?: Option[];
    name: string;
};

const SingleSelect: React.FC<OwnProps> = (props) => {
    const {
        isFirstOptionSelected = false,
        closeMenuOnSelect = true,
        isClearable = false,
        isDisabled = false,
        placeholder = '',
        externalOnChange,
        errorPlacement,
        options,
        label,
        name,
    } = props;

    return (
        <>
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                isFirstOptionSelected={isFirstOptionSelected}
                closeMenuOnSelect={closeMenuOnSelect}
                externalOnChange={externalOnChange}
                errorPlacement={errorPlacement}
                isClearable={isClearable}
                placeholder={placeholder}
                isDisabled={isDisabled}
                component={Select}
                options={options}
                name={name}
            />
        </>
    );
};

export default SingleSelect;
