export const PERMISSIONS = {
    TRUCK_VIEW: 'truck.view',
    TRUCK_CREATE: 'truck.create',
    TRUCK_UPDATE: 'truck.update',
    TRUCK_ARCHIVE: 'truck.archive',
    TRUCK_REGISTRATION_INFO: 'truck.registration-info',
    TRUCK_MODALS_STATUS_AND_LOCATION_UPDATE: 'truck.modals.status-and-location.update',
    TRUCK_LIST_INITIAL_VIEW: 'truck.list.initial.view',

    TRUCK_PARTNER_VIEW: 'truck.partner.view',
    TRUCK_PARTNER_DRIVER_INFO_VIEW: 'truck.partner.driver-info.view',
    TRUCK_PARTNER_RESERVE: 'truck.partner.reserve',
    TRUCK_OWN_RESERVE: 'truck.own.reserve',
    TRUCK_NOTES_VIEW: 'truck.notes.view',
    TRUCK_NOTES_UPDATE: 'truck.notes.update',

    TRUCK_NEARBY_EXPORT: 'truck.nearby.export',

    TRUCK_ALLIANCE_FILTER_VIEW: 'truck.alliance.filter.view',
    TRUCK_ALLIANCE_COMPANY_NAME_VIEW: 'truck.alliance.company-name.view',

    LOAD_OTHER_VIEW: 'load.other.view',
    LOAD_INTERCOMPANY_VIEW: 'load.intercompany.view',
    LOAD_TOTAL_RATE_VIEW: 'load.total-rate.view',
    LOAD_PROFIT_VIEW: 'load.profit.view',
    LOAD_CREATE: 'load.create',
    LOAD_UPDATE: 'load.update',
    LOAD_ARCHIVE: 'load.archive',
    LOAD_LIST_INITIAL_VIEW: 'load.list.initial.view',

    TRIP_MONITOR_VIEW: 'trip-monitor.view',
    TRAVEL_ORDER_VIEW: 'travel-order.view',
    TRAVEL_ORDER_CREATE: 'travel-order.create',
    TRAVEL_ORDER_UPDATE: 'travel-order.update',
    TRAVEL_ORDER_ARCHIVE: 'travel-order.archive',
    TRAVEL_ORDER_DRIVER_RATE_VIEW: 'travel-order.driver-pay.view',
    TRAVEL_ORDER_LIST_INITIAL_VIEW: 'travel-order.list.initial.view',

    INVOICE_VIEW: 'invoice.view',
    INVOICE_UPDATE: 'invoice.update',
    INVOICE_LIST_INITIAL_VIEW: 'invoice.list.initial.view',

    OWNER_SETTLEMENT_VIEW: 'owner-settlement.view',
    OWNER_SETTLEMENT_UPDATE: 'owner-settlement.update',
    OWNER_SETTLEMENT_TOTAL_RATE_VIEW: 'owner-settlement.total-rate.view',
    OWNER_SETTLEMENT_PROFIT_VIEW: 'owner-settlement.profit.view',
    OWNER_SETTLEMENT_DELETE: 'owner-settlement.delete',
    OWNER_SETTLEMENT_LIST_INITIAL_VIEW: 'owner-settlement.list.initial.view',

    CARRIER_SETTLEMENT_VIEW: 'carrier-settlement.view',
    CARRIER_SETTLEMENT_TOTAL_RATE_VIEW: 'carrier-settlement.total-rate.view',
    CARRIER_SETTLEMENT_PROFIT_VIEW: 'carrier-settlement.profit.view',
    CARRIER_SETTLEMENT_UPDATE: 'carrier-settlement.update',
    CARRIER_SETTLEMENT_LIST_INITIAL_VIEW: 'carrier-settlement.list.initial.view',

    OWNER_VIEW: 'owner.view',
    OWNER_CREATE: 'owner.create',
    OWNER_UPDATE: 'owner.update',
    OWNER_ARCHIVE: 'owner.archive',
    OWNER_NOTES_VIEW: 'owner.notes.view',
    OWNER_NOTES_UPDATE: 'owner.notes.update',
    OWNER_LIST_INITIAL_VIEW: 'owner.list.initial.view',
    OWNER_CHANGE_LOG_BUTTON_VIEW: 'owner.change-log.button.view',

    OWNER_FILTER_ALLIANCE_COMPANY_NAME_VIEW: 'owner.filter.alliance-company-name.view',

    DRIVER_VIEW: 'driver.view',
    DRIVER_CREATE: 'driver.create',
    DRIVER_UPDATE: 'driver.update',
    DRIVER_ARCHIVE: 'driver.archive',
    DRIVER_NOTES_VIEW: 'driver.notes.view',
    DRIVER_NOTES_UPDATE: 'driver.notes.update',
    DRIVER_LIST_INITIAL_VIEW: 'driver.list.initial.view',

    DISPATCHER_VIEW: 'dispatcher.view',
    DISPATCHER_CREATE: 'dispatcher.create',
    DISPATCHER_MANAGE_ADMIN: 'dispatcher.manage.admin',
    DISPATCHER_UPDATE: 'dispatcher.update',
    DISPATCHER_ARCHIVE: 'dispatcher.archive',
    DISPATCHER_LIST_INITIAL_VIEW: 'dispatcher.list.initial.view',

    CARRIER_VIEW: 'carrier.view',
    CARRIER_ALLIANCE_VIEW: 'carrier.alliance.view',
    CARRIER_CREATE: 'carrier.create',
    CARRIER_UPDATE: 'carrier.update',
    CARRIER_ARCHIVE: 'carrier.archive',
    CARRIER_LIST_INITIAL_VIEW: 'carrier.list.initial.view',

    CUSTOMER_VIEW: 'customer.view',
    CUSTOMER_ALLIANCE_VIEW: 'customer.alliance.view',
    CUSTOMER_CREATE: 'customer.create',
    CUSTOMER_UPDATE: 'customer.update',
    CUSTOMER_ARCHIVE: 'customer.archive',
    CUSTOMER_MERGE: 'customer.merge',
    CUSTOMER_LIST_INITIAL_VIEW: 'customer.list.initial.view',

    FACILITY_VIEW: 'facility.view',
    FACILITY_CREATE: 'facility.create',
    FACILITY_UPDATE: 'facility.update',
    FACILITY_ARCHIVE: 'facility.archive',
    FACILITY_LIST_INITIAL_VIEW: 'facility.list.initial.view',

    FACTORING_COMPANY_VIEW: 'factoring-company.view',
    FACTORING_COMPANY_CREATE: 'factoring-company.create',
    FACTORING_COMPANY_UPDATE: 'factoring-company.update',
    FACTORING_COMPANY_ARCHIVE: 'factoring-company.archive',
    FACTORING_COMPANY_BANKING_INFO_VIEW: 'factoring-company-banking-info.view',
    FACTORING_COMPANY_LIST_INITIAL_VIEW: 'factoring-company.list.initial.view',

    REPORT_VIEW: 'report.view',

    LOG_VIEW: 'log.view',

    MY_COMPANY_UPDATE: 'my-company.update',
    MY_COMPANY_VIEW: 'my-company.view',
    MY_COMPANY_CONTACT_INFORMATION_VIEW: 'my-company.contact-information.view',
    MY_COMPANY_BILLING_DETAILS_VIEW: 'my-company.billing-details.view',
    MY_COMPANY_BANK_DETAILS_VIEW: 'my-company.bank-details.view',
    MY_COMPANY_FACTORING_INFORMATION_VIEW: 'my-company.factoring-information.view',

    MENU_MY_COMPANY_VIEW: 'menu.my-company.view',
    MENU_TRUCK_FULL_ALLIANCE_VIEW: 'menu.truck-full-alliance.view',
    MENU_TRUCK_LIST_VIEW: 'menu.truck-list.view',
    MENU_MAP_VIEW: 'menu.map.view',
    MENU_TRUCK_NEARBY_VIEW: 'menu.trucks-nearby.view',
    MENU_AUCTION_VIEW: 'menu.auction.view',
    MENU_LOADS_VIEW: 'menu.loads.view',
    MENU_TRIP_MONITOR_VIEW: 'menu.trip-monitor.view',
    MENU_INVOICES_VIEW: 'menu.invoices.view',
    MENU_OWNER_SETTLEMENTS_VIEW: 'menu.owner-settlements.view',
    MENU_CARRIER_SETTLEMENTS_VIEW: 'menu.carrier-settlements.view',
    MENU_DRIVERS_VIEW: 'menu.drivers.view',
    MENU_OWNERS_VIEW: 'menu.owner.view',
    MENU_USERS_VIEW: 'menu.users.view',
    MENU_CARRIERS_VIEW: 'menu.carriers.view',
    MENU_CUSTOMERS_VIEW: 'menu.customers.view',
    MENU_FACILITIES_VIEW: 'menu.facilities.view',
    MENU_FACTORING_COMPANIES_VIEW: 'menu.factoring-companies.view',
    MENU_CHANGES_LOG_VIEW: 'menu.changes-log.view',
    MENU_REPORTS_VIEW: 'menu.reports.view',
    MENU_RELEASE_NOTES_VIEW: 'menu.release-notes.view',

    REPORT_CRATED_BY_LOADS_GENERATE: 'report.create-by-loads.generate',
    REPORT_LOADS_REPORT_GENERATE: 'report.loads-report.generate',
    REPORT_CUSTOMER_ON_HOLD_GENERATE: 'report.customers-on-hold.generate',
    REPORT_TRUCK_RESERVATIONS_GENERATE: 'report.trucks-reservations.generate',
    REPORT_TRUCKS_REPORT_GENERATE: 'report.trucks-report.generate',
    REPORT_AVAILABILITY_LIST_GENERATE: 'report.availability-list.generate',
    REPORT_OWNERS_INFO_GENERATE: 'report.owners-info.generate',
    REPORT_OWNER_PAY_SUMMARY_GENERATE: 'report.owner-pay-summary.generate',
    REPORT_GROSS_MARGIN_REPORT_GENERATE: 'report.gross-margin-report.generate',
    REPORT_CARRIER_SETTLEMENTS_GENERATE: 'report.carrier-settlements.generate',
    REPORT_PAY_PERIOD_GENERATE: 'report.pay-period.generate',
    REPORT_LOGIN_LOGS_REPORT_GENERATE: 'report.login-logs-report.generate',
    REPORT_CSAT_SURVEY_REPORT_GENERATE: 'report.csat-survey-report.generate',
    REPORT_DISPATCHERS_LOGS_REPORT_GENERATE: 'report.dispatchers-logs-report.generate',
} as const;
