import React from 'react';
import trimStart from 'lodash/trimStart';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextInputWithAutoCompleteDropdown from 'components/ui/Form/TextInputWithAutoCompleteDropdown';

import {COMPANY_NAME_FIELD_MAX_LENGTH} from 'pages/Carriers/constants';
import useSelectCompaniesActions from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesActions';
import useModalActions from 'pages/Trucks/hooks/useModalActions';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

import SelectCompaniesInfo from './components/SelectCompaniesInfo';
import SelectCompaniesSearchResult from './components/SelectCompaniesSearchResult';
import SelectCompaniesTable from './components/SelectCompaniesTable';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    externalFormName: string;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, form, externalFormName} = props;

    const {getSimilarCompaniesDropdown, saveSelectedCompanies} = useSelectCompaniesActions();
    const formValues = useGetReduxFormValues<SelectCompaniesModalFormValues>(form);
    const {closeModal} = useModalActions();

    const handleSave = () => {
        saveSelectedCompanies({formValues, formName: externalFormName});
        closeModal();
    };

    const getSimilarCompanies = (searchValue: string) => {
        return getSimilarCompaniesDropdown({formValues: {companyName: searchValue}});
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-body__main">
                <Field
                    component={TextInputWithAutoCompleteDropdown}
                    maxLength={COMPANY_NAME_FIELD_MAX_LENGTH}
                    normalize={(value) => trimStart(value)}
                    placeholder="enter company name"
                    fetchData={getSimilarCompanies}
                    name={getName('companyName')}
                    isHighlight={true}
                />

                <SelectCompaniesSearchResult />

                <div className="bottom-hr mt5" />

                <SelectCompaniesInfo internalFormName={form} />

                <SelectCompaniesTable internalFormName={form} />
            </div>

            <div className="modal-body__bottom">
                <Button onClick={closeModal}>Cancel</Button>

                <Button colorTheme="blue" onClick={handleSave}>
                    Save
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({})(Form);
