import commonModalMap from 'components/ui/modals/modalMap';
import locationEventModalMap from 'components/common/LocationEvents/modals/modalMap';
import carriersModalMap from 'pages/Carriers/components/modals/modalMap';
import customersModalMap from 'pages/Customers/components/common/modals/modalMap';
import facilitiesModalMap from 'pages/Facilities/components/modals/modalMap';
import factoringCompaniesModalMap from 'pages/FactoringCompanies/components/modals/modalMap';
import driversModalMap from 'pages/Drivers/components/modals/modalMap';
import trucksMapModalMap from 'pages/TrucksMap/components/modals/modalMap';
import invoicesModals from 'pages/Invoices/components/common/modals/modalMap';
import settlementsModal from 'pages/Settlements/components/common/modals/modalMap';
import trucksModalMap from 'pages/Trucks/components/modals/modalMap';
import ownersModalMap from 'pages/Owners/components/modals/modalMap';
import trucksNearbyModalMap from 'pages/TrucksNearby/components/common/modals/modalMap';
import dispatchersModalMap from 'pages/Dispatchers/components/modals/modalMap';
import loadBoardModalMap from 'pages/LoadBoard/components/modals/modalMap';
import travelOrdersModalMap from 'pages/TravelOrders/components/common/modals/modalMap';
import loadsModalMap from 'pages/Loads/components/common/modals/modalMap';

import trucksCommonModalMap from 'components/common/Truck/modals/modalMap';

const combinedModalMap = {
    ...commonModalMap,
    ...locationEventModalMap,
    ...carriersModalMap,
    ...customersModalMap,
    ...facilitiesModalMap,
    ...factoringCompaniesModalMap,
    ...driversModalMap,
    ...trucksMapModalMap,
    ...invoicesModals,
    ...settlementsModal,
    ...trucksModalMap,
    ...ownersModalMap,
    ...trucksNearbyModalMap,
    ...dispatchersModalMap,
    ...trucksCommonModalMap,
    ...travelOrdersModalMap,
    ...loadBoardModalMap,
    ...loadsModalMap,
};

export default combinedModalMap;
