import {push} from 'connected-react-router';
import has from 'lodash/has';
import {destroy, reset} from 'redux-form';

import adminActions from 'store/reducers/adminSettings/actionCreators';
import {getPermissions} from 'store/selectors';

import {PERMISSIONS} from 'core/entities/Permissions';

import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import * as entityNames from 'utils/data/entityNames';
import modalBodyTypes from 'utils/data/modalBodyTypes';
import {FACTORING_COMPANIES} from 'utils/data/pages';

function showFormResetModal(formName, onConfirmCallback, config): any {
    return function (dispatch) {
        if (!formName || typeof formName !== 'string') {
            throw new TypeError(
                `Expected argument formName type to be a 'string'. Got a '${typeof formName}' instead.`,
            );
        }
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    ...config,
                    buttonType: 'warning',
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: () => {
                        dispatch(reset(formName));
                        if (onConfirmCallback) {
                            onConfirmCallback();
                        }
                    },
                },
            }),
        );
    };
}

export function showFormClearFieldsWarning(formName, onConfirmCallback?): any {
    return function (dispatch) {
        const config = {
            title: 'Clear All Fields',
            leftButtonTitle: 'Keep Creating',
            rightButtonTitle: 'Clear Fields',
            bodyType: 'ClearAllFieldsForm',
            buttonType: 'success',
        };
        dispatch(showFormResetModal(formName, onConfirmCallback, config));
    };
}

export function showFormResetWarning(formName, onConfirmCallback?): any {
    return function (dispatch) {
        const config = {
            title: 'Reset Fields',
            leftButtonTitle: 'KEEP',
            rightButtonTitle: 'Reset Fields',
            bodyType: 'ResetFieldsForm',
            buttonType: 'success',
        };
        dispatch(showFormResetModal(formName, onConfirmCallback, config));
    };
}

export function showDeleteFileWarning(onDelete): any {
    return function (dispatch) {
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Delete file',
                    errorMessage: 'This file is going to be removed.',
                    leftButtonTitle: 'Cancel',
                    rightButtonTitle: 'Delete',
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: onDelete,
                },
            }),
        );
    };
}

export function showTruckDispatcherNote(truck, onSubmit): any {
    return function (dispatch, getState) {
        const permissions = getPermissions(getState());

        dispatch(
            openModal({
                modalName: commonModalNames.truckDispatcherNoteModal,
                data: {
                    truck,
                    isAddNoteDisabled: !has(permissions, PERMISSIONS.TRUCK_NOTES_UPDATE),
                },
                handlers: {
                    onSubmit,
                },
            }),
        );
    };
}

export function showTruckNotesModal(truck, handleReceiveDispatcherNote): any {
    return function (dispatch) {
        dispatch(
            openModal({
                modalName: commonModalNames.truckNotesModal,
                data: {
                    truck,
                },
                handlers: {
                    handleReceiveDispatcherNote,
                },
            }),
        );
    };
}

export function showEntityActionSuccess(params: {
    entity: {id?: number; is_deleted: boolean};
    customEntityName?: string;
    entityName: string;
    entityLabel: string;
    action: 'created' | 'updated' | 'restored';
    formName?: string;
}): any {
    const getEntityGeneralListSettings = (entityName) =>
        adminActions.setAdminSettings({[entityName]: {showDeleted: false}});

    const entitiesSettings = {
        [entityNames.ENTITY_NAME_CARRIER]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_CARRIERS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_CARRIERS),
        },
        [entityNames.ENTITY_NAME_CUSTOMER]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_CUSTOMERS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_CUSTOMERS),
        },
        [entityNames.ENTITY_NAME_DISPATCHER]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_DISPATCHERS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_DISPATCHERS),
        },
        [entityNames.ENTITY_NAME_DRIVER]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_DRIVERS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_DRIVERS),
        },
        [entityNames.ENTITY_NAME_FACILITY]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_FACILITIES}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_FACILITIES),
        },
        [entityNames.ENTITY_NAME_FACTORING_COMPANY]: {
            getPathToList: () => `/${FACTORING_COMPANIES}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_FACTORING_COMPANIES),
        },
        [entityNames.ENTITY_NAME_OWNER]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_OWNERS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_OWNERS),
        },
        [entityNames.ENTITY_NAME_TRUCK]: {
            getPathToList: () => `/${entityNames.ENTITY_NAME_TRUCKS}`,
            getListSettings: () => getEntityGeneralListSettings(entityNames.ENTITY_NAME_TRUCKS),
        },
    };

    return function (dispatch) {
        const {entity, entityName, entityLabel, customEntityName, action, formName} = params;

        const getTitle = () => {
            if (action === 'created') {
                return 'Successful create';
            }
            if (action === 'updated') {
                return 'Successful update';
            }
            return 'Successful restore';
        };

        const getBodyType = () => {
            if (action === 'created') {
                return modalBodyTypes.CREATE_SUCCESS;
            }
            if (action === 'updated') {
                return modalBodyTypes.UPDATE_SUCCESS;
            }
            return modalBodyTypes.RESTORE_SUCCESS;
        };

        const modalAction = openModal({
            modalName: commonModalNames.informationModal,
            data: {
                title: getTitle(),
                entity,
                toProfileLabel: entityLabel,
                entityName,
                bodyType: getBodyType(),
                buttonType: 'success',
                customEntityName,
            },
            handlers: {
                rightButtonHandler: () => {
                    const settings = entitiesSettings[entityName];
                    if (settings) {
                        const listSettings = settings.getListSettings();
                        const pathToList = settings.getPathToList();
                        if (action === 'restored') {
                            dispatch(listSettings);
                        }
                        dispatch(push(pathToList));
                    }
                    if (formName) {
                        dispatch(destroy(formName));
                    }
                },
            },
        });
        dispatch(modalAction);
    };
}
