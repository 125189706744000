import {useSelector} from 'react-redux';

import * as selectors from 'pages/Trucks/redux/selectors/selectCompanies';

type UseSelectCompaniesState = {
    searchMatchCount: ReturnType<typeof selectors.getCompaniesSearchMatchCount>;
    companiesByFilters: ReturnType<typeof selectors.getCompaniesByFilters>;
    searchParams: ReturnType<typeof selectors.getCompaniesSearchParams>;
    companies: ReturnType<typeof selectors.getCompanies>;
};

const useSelectCompaniesState = (): UseSelectCompaniesState => ({
    searchMatchCount: useSelector(selectors.getCompaniesSearchMatchCount),
    companiesByFilters: useSelector(selectors.getCompaniesByFilters),
    searchParams: useSelector(selectors.getCompaniesSearchParams),
    companies: useSelector(selectors.getCompanies),
});

export default useSelectCompaniesState;
