import {combineReducers} from 'redux';

import current from './current';
import list from './list';
import selectCompaniesModal from './selectCompanies';

export default combineReducers({
    selectCompaniesModal,
    current,
    list,
});
