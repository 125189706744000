import React from 'react';

import {getTruckNumber} from 'core/entities/Truck/modules/common/getters/getTruckNumber';
import Truck from 'core/entities/Truck/types';

type OwnProps = {
    truck: Truck;
};

const CompaniesTableEmpty: React.FC<OwnProps> = (props) => {
    const {truck} = props;

    const truckNumber = getTruckNumber(truck);

    return (
        <tr>
            <td colSpan={4}>
                <div className="mt15 mb20 text-center">
                    There are no companies that the Truck #{truckNumber} works with
                </div>
            </td>
        </tr>
    );
};

export default CompaniesTableEmpty;
