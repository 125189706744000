import {createSelector} from 'reselect';

import {ListState} from '../reducers/list';

export const getSearchParams = (state): ListState['searchParams'] => state.trucksNearby.list.searchParams;
export const getListPagination = (state): ListState['pagination'] => state.trucksNearby.list.pagination;
export const getExpandedIDs = (state): ListState['expandedIDs'] => state.trucksNearby.list.expandedIDs;
export const getPagination = (state): ListState['pagination'] => state.trucksNearby.list.pagination;
export const getSorting = (state): ListState['sorting'] => state.trucksNearby.list.sorting;
export const getTrucks = (state): ListState['items'] => state.trucksNearby.list.items;
export const getDestinationPoint = (state): ListState['locationPoints']['destination'] =>
    state.trucksNearby.list.locationPoints?.destination;
export const getExternalSearchParams = (state): ListState['externalSearchParams'] =>
    state.trucksNearby.list.externalSearchParams;
export const getOriginPoint = (state): ListState['locationPoints']['origin'] =>
    state.trucksNearby.list.locationPoints?.origin;

// For "New quote" modal
export const getDeliveryCityLine = (state): ListState['searchParams']['destinationPoint'] =>
    state.trucksNearby.list.searchParams?.destinationPoint;
export const getPickupCityLine = (state): ListState['searchParams']['originPoint'] =>
    state.trucksNearby.list.searchParams?.originPoint;

export const generateQuoteTemplate = createSelector(
    [getPickupCityLine, getDeliveryCityLine],
    (pickupCityLine, deliveryCityLine) => {
        const quoteTemplateArray: string[] = [];

        // Template header
        quoteTemplateArray.push('Delta Express Quote');

        const [deliveryCity, deliveryState] = deliveryCityLine?.split(',') || [];
        const [pickupCity, pickupState] = pickupCityLine?.split(',') || [];

        // Pickup details
        quoteTemplateArray.push(`Pick up\nDate and time: TBD\n${pickupCity}, ${pickupState}`);

        // Delivery details
        let deliveryDetails = `Delivery\nDate and time: TBD`;

        if (deliveryCityLine) {
            deliveryDetails += `\n${deliveryCity}, ${deliveryState}`;
        } else {
            deliveryDetails += `\nCity, ST (TBD)`;
        }

        quoteTemplateArray.push(deliveryDetails);

        // Pieces and weight
        quoteTemplateArray.push('0 pcs 0 lbs');

        // Reply text
        quoteTemplateArray.push(
            'If you interested - fill in the following template and use it as reply text:\n' +
                `your truck #\nrate, $\nlane ${pickupState} - ${deliveryState || 'TBD'}`,
        );

        // Warning text
        quoteTemplateArray.push(
            'Please be aware that you are under 15 mins reserve for Delta Express once you replied to this quote',
        );

        return quoteTemplateArray.join('\n\n');
    },
);
