import Truck from 'core/entities/Truck/types';
import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';

import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

export const handleReducerByTrucksReceived = (params: {state: ListState; items: Truck[]}) => {
    const {state, items} = params;

    const trucksIdsSet = new Set(items.map((item) => item.id));

    return {
        ...state,
        items,
        trucksIdsSet,
    };
};

export const handleReducerByDispatcherNoteReceived = (params: {
    state: ListState;
    truckID: number;
    noteApiResponse: DispatcherNoteApi;
}) => {
    const {state, truckID, noteApiResponse} = params;

    const items = state.items.map((item) => (item.id === truckID ? {...item, ...noteApiResponse} : item));

    return {
        ...state,
        items,
    };
};
