import React, {FC, memo} from 'react';
import {Modal} from 'react-bootstrap';

import Truck from 'core/entities/Truck/types';

import Form from 'components/common/Truck/modals/DispatcherNoteModal/components/Form';

type OwnProps = {
    data: {truck: Truck; isAddNoteDisabled?: boolean};
    handlers: {[name: string]: Function};
    onClose();
};

const DispatcherNoteModal: FC<OwnProps> = memo((props) => {
    const {
        data: {truck, isAddNoteDisabled},
        handlers: {onSubmit},
        onClose,
    } = props;

    const initValues = {
        text: truck.dispatcher_note,
    };

    const formSubmitHandler = (values) => {
        const {text} = values;

        onSubmit(truck.id, text);
        onClose();
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="custom-modal standard-form-modal"
            backdrop="static"
            size="sm"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>{`Dispatcher's Note of the Truck#${truck.number}`}</Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form
                        form="choose-owner-form"
                        onSubmit={formSubmitHandler}
                        onClose={onClose}
                        initialValues={initValues}
                        isAddNoteDisabled={isAddNoteDisabled}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default DispatcherNoteModal;
