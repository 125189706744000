import isFunction from 'lodash/isFunction';
import {change} from 'redux-form';

import * as appActions from 'store/actions';

import Carrier from 'core/entities/Carrier/types';
import {fetchCarrierList} from 'core/gateways/CarrierApiGateway/requests';

import {characteristics} from 'pages/Trucks/constants/fields';
import * as actionTypes from 'pages/Trucks/redux/actionTypes/selectCompanies';
import {transformDataToRequestBody} from 'pages/Trucks/redux/mappers/selectCompanies';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

export const selectCompaniesActionCreators = {
    getCompaniesByFilters: (payload: {filters: Partial<SelectCompaniesModalFormValues>}) =>
        ({type: actionTypes.COMPANIES_BY_FILTERS_RECEIVED, payload} as const),
    receiveCompaniesSearchParams: (payload: {filters: Partial<SelectCompaniesModalFormValues>}) =>
        ({type: actionTypes.COMPANIES_SEARCH_PARAMS_RECEIVED, payload} as const),
    receiveCompaniesList: (payload: {companies: Carrier[]}) =>
        ({type: actionTypes.COMPANIES_RECEIVED, payload} as const),
    clearSelectCompaniesState: () => ({type: actionTypes.COMPANIES_STATE_CLEARED} as const),
};

export const getCompaniesList = (params: {requestPayload}) => async (dispatch) => {
    const {requestPayload} = params;

    try {
        const {data} = await fetchCarrierList(requestPayload);

        return {items: data};
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const getInitialCompaniesList = () => async (dispatch, getState) => {
    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    dispatch(appActions.showLoader());

    const {items} = await dispatch(getCompaniesList({requestPayload}));

    dispatch(selectCompaniesActionCreators.receiveCompaniesList({companies: items}));
};

export const getSimilarCompaniesDropdown = (params: {formValues: Partial<SelectCompaniesModalFormValues>}) => async (
    dispatch,
    getState,
) => {
    const {formValues} = params;

    const state = getState();

    const requestPayload = transformDataToRequestBody({state, formValues});

    const {items} = await dispatch(getCompaniesList({requestPayload}));

    return (items || []).map((item) => ({label: item.company_name, value: item.company_name}));
};

export const getCompaniesBySorting = () => async (dispatch, getState) => {
    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    dispatch(appActions.showLoader());

    const {items} = await dispatch(getCompaniesList({requestPayload}));

    dispatch(selectCompaniesActionCreators.receiveCompaniesList({companies: items}));
};

export const getCompaniesByFilters = (params: {
    filters: Partial<SelectCompaniesModalFormValues>;
    onPostSuccessFunc?: () => void;
}) => (dispatch) => {
    const {filters, onPostSuccessFunc} = params;

    if (isFunction(onPostSuccessFunc)) {
        onPostSuccessFunc();
    }

    dispatch(selectCompaniesActionCreators.getCompaniesByFilters({filters}));
    dispatch(selectCompaniesActionCreators.receiveCompaniesSearchParams({filters}));
};

export const saveSelectedCompanies = (params: {
    formValues: Partial<SelectCompaniesModalFormValues>;
    formName: string;
}) => (dispatch) => {
    const {formValues, formName} = params;

    dispatch(change(formName, characteristics.workWithCompanies, formValues.selectedCompanies));
};

export const clearSelectCompaniesState = () => (dispatch) => {
    dispatch(selectCompaniesActionCreators.clearSelectCompaniesState());
};
