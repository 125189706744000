import React from 'react';
import {isEmpty} from 'lodash';

import AppliedFilters from 'components/ui/AppliedFilters';

import useSearchResult from './hooks/useSearchResult';

const SelectCompaniesSearchResult: React.FC = () => {
    const {filters, totalCount} = useSearchResult();

    if (isEmpty(filters)) {
        return null;
    }

    return <AppliedFilters filters={filters} totalCount={totalCount} />;
};

export default SelectCompaniesSearchResult;
