import React from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import {Field} from 'redux-form';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import SortingIcon from 'components/ui/SortingIcon';

import useSelectCompaniesActions from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesActions';
import useSelectCompaniesState from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';

import SelectCompaniesTableRow from './components/SelectCompaniesTableRow';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    externalFormName: string;
    internalFormName: string;
};

const SelectCompaniesTable: React.FC<OwnProps> = (props) => {
    const {internalFormName, externalFormName} = props;

    const changeFormValues = useChangeReduxFormValues(internalFormName);
    const {companiesByFilters, companies} = useSelectCompaniesState();
    const {getCompaniesBySorting} = useSelectCompaniesActions();

    const handleSortingClick = () => {
        getCompaniesBySorting();
    };

    const handleCheckBoxClick = (value: boolean) => {
        if (value) {
            changeFormValues({
                [getName('selectedCompanies')]: map(companies, (item) => ({label: item.company_name, value: item.id})),
            });
        } else {
            changeFormValues({
                [getName('isAllCompaniesSelected')]: null,
                [getName('selectedCompanies')]: null,
            });

            return;
        }

        return value;
    };

    const renderCompanies = (companiesByFilters || companies || []).map((company, index) => (
        <SelectCompaniesTableRow key={company.id} company={company} externalFormName={externalFormName} index={index} />
    ));

    return (
        <div className={classNames(styles.table, styles.scroll, 'standard-table-style')}>
            <table className="table mb0">
                <thead>
                    <tr>
                        <th>
                            <div className="d-flex">
                                <Field
                                    formatValueBeforeChange={handleCheckBoxClick}
                                    name={getName('isAllCompaniesSelected')}
                                    component={CheckBoxInput}
                                    position="right"
                                />
                                <SortingIcon
                                    sortListName="selectCompaniesModal"
                                    onClick={handleSortingClick}
                                    sortBy="company_name"
                                    title="COMPANY NAME"
                                    sortType="alpha"
                                />
                            </div>
                        </th>

                        <th>PREFIX</th>

                        <th>PHYSICAL ADDRESS</th>

                        <th>MC#</th>
                    </tr>
                </thead>

                <tbody className="table-body-bordered">{renderCompanies}</tbody>
            </table>
        </div>
    );
};

export default SelectCompaniesTable;
