import {useSelector} from 'react-redux';

import {AppState} from 'store';
import * as selectors from 'store/reducers/sort/selectors';
import {SortListName} from 'store/reducers/sort/types';

type UseSortingState = {
    currentSortFrom: ReturnType<typeof selectors.getCurrentSortFrom>;
    currentSortBy: ReturnType<typeof selectors.getCurrentSortBy>;
};

const useSortingState = (sortListName: SortListName): UseSortingState => ({
    currentSortFrom: useSelector((state: AppState) => selectors.getCurrentSortFrom(state, sortListName)),
    currentSortBy: useSelector((state: AppState) => selectors.getCurrentSortBy(state, sortListName)),
});

export default useSortingState;
