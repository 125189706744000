import {AppState} from 'store';

import {AppSettingsState} from '.';

export const getSidebarMenu = (state: AppState): AppSettingsState['sidebarMenu'] => state.appSettings.sidebarMenu;

export const getCurrentDistanceFormat = (state: AppState): AppSettingsState['distance_format'] =>
    state.appSettings.distance_format;

export const getCurrentWeightFormat = (state: AppState): AppSettingsState['default_weight_format'] =>
    state.appSettings.default_weight_format;

export const getCurrentDimensionsFormat = (state: AppState): AppSettingsState['default_dimensions_format'] =>
    state.appSettings.default_dimensions_format;

export const getCurrentTimeZone = (state: AppState): AppSettingsState['timezone'] => state.appSettings.timezone;

export const getCurrentDateFormat = (state: AppState): AppSettingsState['date_format'] => state.appSettings.date_format;

export const getCurrentTimeFormat = (state: AppState): AppSettingsState['time_format'] => state.appSettings.time_format;

export const getCurrentRegion = (state: AppState): AppSettingsState['map_region'] => state.appSettings.map_region;

export const getSurveyIsEnabled = (state: AppState): AppSettingsState['survey_is_enabled'] =>
    state.appSettings.survey_is_enabled;

export const getCompanyShortTitle = (state: AppState): AppSettingsState['company_short_title'] =>
    state.appSettings.company_short_title;

export const getCompanyFullTitle = (state: AppState): AppSettingsState['company_full_title'] =>
    state.appSettings.company_full_title;

export const getSendQuote = (state: AppState): AppSettingsState['is_send_quote'] => state.appSettings.is_send_quote;

export const getDomainLogo = (state: AppState): AppSettingsState['domainLogo'] => state.appSettings.domainLogo;

export const getSupportPhone = (state: AppState): AppSettingsState['support']['support_phone'] =>
    state.appSettings.support?.support_phone;

export const getSupportEmail = (state: AppState): AppSettingsState['support']['support_email'] =>
    state.appSettings.support?.support_email;

export const getSidebarExpanded = (state: AppState): AppSettingsState['isSidebarExpanded'] =>
    state.appSettings.isSidebarExpanded;

export const getTravelOrdersUpdatingTime = (state: AppState): AppSettingsState['updatingTravelOrdersTime'] =>
    state.appSettings.updatingTravelOrdersTime;
