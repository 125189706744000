import Truck from 'core/entities/Truck/types';

import * as types from 'pages/TrucksNearby/redux/actionTypes/workWithCompanies';

export const initWorkWithCompanies = (params: {truck: Truck}) => (dispatch) => {
    const {truck} = params;

    dispatch({type: types.WORK_WITH_COMPANIES_INITIALIZED, payload: {truck}});
};

export const getCompaniesByOrderBy = () => (dispatch) => {
    dispatch({type: types.WORK_WITH_COMPANIES_ORDER_BY_RECEIVED});
};

export const clearWorkWithCompaniesState = () => (dispatch) => {
    dispatch({type: types.WORK_WITH_COMPANIES_STATE_CLEARED});
};
