export const EXTERNAL_SEARCH_PARAMS_RECEIVED = 'TNB/EXTERNAL_SEARCH_PARAMS_RECEIVED' as const;
export const EXTERNAL_SEARCH_PARAMS_CLEARED = 'TNB/EXTERNAL_SEARCH_PARAMS_CLEARED' as const;
export const DISPATCHER_NOTE_RECEIVED = 'TNB/DISPATCHER_NOTE_RECEIVED' as const;
export const LOCATION_POINTS_RECEIVED = 'TNB/LOCATION_POINTS_RECEIVED' as const;
export const SEARCH_PARAMS_RECEIVED = 'TNB/SEARCH_PARAMS_RECEIVED' as const;
export const LIST_SORT_BY_RECEIVED = 'TNB/LIST_SORT_BY_RECEIVED' as const;
export const EXPANDED_IDS_RECEIVED = 'TNB/EXPANDED_IDS_RECEIVED' as const;
export const PAGINATION_RECEIVED = 'TNB/PAGINATION_RECEIVED' as const;
export const LIST_STATE_CLEARED = 'TNB/LIST_STATE_CLEARED' as const;
export const TRUCKS_RECEIVED = 'TNB/TRUCKS_RECEIVED' as const;

export const SSE_DATA_RECEIVED = 'SSE/TNB/DATA_RECEIVED' as const;
