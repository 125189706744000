import Carrier from 'core/entities/Carrier/types';
import Driver, {Citizenship} from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

import GeneralNote from 'types/GeneralNote';

type UnknownEntity = Record<string, any>;

// eslint-disable-next-line no-shadow
export enum Sex {
    Male = 'Male',
    Female = 'Female',
}

type PayRecordSettings = {
    is_send_owner: boolean;
    is_send_coordinator: boolean;
};

type BillingOwner = {
    pay_record_send_settings: PayRecordSettings;
};

interface Owner {
    id: number;
    trucks: Truck[];
    additional_address_line?: string;
    additional_mobile_phone?: string;
    address_line: string;
    citizenship: Citizenship;
    city: string;
    comment?: string;
    coordinator_additional_mobile_phone?: string | null;
    coordinator_ec_contact_phone?: string;
    coordinator_ec_name?: string;
    coordinator_ec_relationship?: string;
    coordinator_email?: string;
    coordinator_full_name?: string | null;
    coordinator_language: string;
    coordinator_mobile_phone?: string | null;
    country: string;
    date_of_birthday?: string;
    country_of_birth: string;
    document: string;
    document_company_name?: string;
    document_number?: number;
    drivers: Driver[];
    ec_name?: string;
    ec_phone?: string;
    email?: string;
    full_name: string;
    hire_date: string;
    hired_by: UnknownEntity;
    home_lat: string;
    home_lng: string;
    home_phone?: string;
    is_deleted: boolean;
    language: string;
    mobile_phone: string;
    not_working_with?: string;
    owner_with_coordinator: boolean;
    policy_eff?: string | null;
    policy_exp?: string | null;
    policy_number?: string | null;
    relationship?: string | null;
    state: string;
    status: string;
    zip: string;
    generalNotes?: GeneralNote[];
    is_owner_send_pay_records: boolean;
    is_coordinator_send_pay_records: boolean;
    billing_owner: BillingOwner;
    alliance_company_name?: string;
    companies: Carrier[];
}

export const getEntityFieldName = (name: keyof Owner): string => name;

export default Owner;
