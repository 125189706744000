import {hideLoader} from 'store/actions';
import * as appModalActions from 'store/actions/modalActions';

import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import Owner from 'core/entities/Owner/types';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {modalNames} from 'pages/Owners/components/modals/modalMap';

import * as entityNames from 'utils/data/entityNames';

export function showOwnerDuplicateModal(owner: Owner) {
    return function (dispatch) {
        dispatch(
            modalActions.openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Error',
                    entity: owner,
                    entityName: entityNames.ENTITY_NAME_OWNER,
                    rightButtonTitle: 'ok',
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
            }),
        );
        dispatch(hideLoader());
    };
}

export const showSuccessModal = (params: {owner: Owner; action: 'created' | 'updated' | 'restored'}) => {
    return function (dispatch) {
        const {owner, action} = params;
        const successAction = appModalActions.showEntityActionSuccess({
            entity: owner,
            entityName: entityNames.ENTITY_NAME_OWNER,
            entityLabel: getOwnerName(owner),
            action,
        });
        dispatch(successAction);
    };
};

export const showSelectCompaniesModal = (params: {formName: string}) => (dispatch) => {
    const {formName} = params;

    dispatch(modalActions.openModal({modalName: modalNames.selectCompaniesModal, data: {formName}}));
};
