import {useDispatch} from 'react-redux';
import {clearFields} from 'redux-form';

type UseClearReduxFormValue = (fieldName: string | string[]) => void;

const useClearReduxFormValue = (formName: string): UseClearReduxFormValue => {
    const dispatch = useDispatch();

    return (fieldName) =>
        dispatch(clearFields(formName, false, false, ...(Array.isArray(fieldName) ? fieldName : [fieldName])));
};

export default useClearReduxFormValue;
