import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import OverlayTriggerCustom from '../OverlayTriggerCustom';

import './style.scss';

const CheckBoxInput = (props) => {
    const {
        label,
        input: {value, onChange},
        position = 'left',
        disabled,
        meta,
        className,
        redesign,
        formatValueBeforeChange,
        valueAs = false,
    } = props;

    const handleCheck = () => {
        if (disabled) {
            return;
        }

        if (valueAs) {
            onChange(value ? null : valueAs);
            return;
        }

        const nextValue = !value;

        if (isFunction(formatValueBeforeChange)) {
            onChange(formatValueBeforeChange(nextValue));
        } else {
            onChange(nextValue);
        }
    };

    const checkValidation = () => {
        if (!meta) {
            return null;
        }
        if (meta.pristine && meta.submitFailed && meta.error) {
            return 'error';
        }
        if (meta.pristine) {
            return null;
        }
        if (meta.error) {
            return 'error';
        }
        if (!meta.active) {
            return null;
        }
        return 'success';
    };

    const containerClassName = classNames(
        `checkbox_single_component ${checkValidation(meta) ? 'has-error' : ''}`.trim(),
        className,
        {redesign},
    );
    const checkedClassName = `iw_cb_shell ${value ? 'iw_cb_shell_checked' : ''}`.trim();
    const disabledClassName = `${disabled ? 'disabled' : ''}`.trim();

    return (
        <div className={containerClassName}>
            <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                <div onClick={handleCheck} className={`${checkedClassName} ${disabledClassName}`}>
                    {position === 'left' ? (
                        <>
                            <label>{label}</label>&nbsp;
                            <span className="cb_square" />
                        </>
                    ) : (
                        <>
                            <span className="cb_square" />
                            <label>{label}</label>
                        </>
                    )}
                </div>
            </OverlayTriggerCustom>
        </div>
    );
};

export default CheckBoxInput;
