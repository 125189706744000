import ArchivingError from './ArchivingError';
import ArchivingSuccess from './ArchivingSuccess';
import ArchivingWarn from './ArchivingWarn';
import SelectCompanies from './SelectCompanies';

export const modalNames = {
    selectCompaniesModal: 'OWNERS/SELECT_COMPANIES_MODAL',
    archivingSuccess: 'OWNERS/ARCHIVING_SUCCESS_MODAL',
    archivingError: 'OWNERS/ARCHIVING_ERROR_MODAL',
    archivingWarn: 'OWNERS/ARCHIVING_WARN_MODAL',
};

export default {
    [modalNames.selectCompaniesModal]: SelectCompanies,
    [modalNames.archivingSuccess]: ArchivingSuccess,
    [modalNames.archivingError]: ArchivingError,
    [modalNames.archivingWarn]: ArchivingWarn,
};
