import {useDispatch} from 'react-redux';

import * as selectCompaniesActions from 'pages/Trucks/redux/actions/selectCompanies';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

type UseSelectCompaniesActions = {
    saveSelectedCompanies: (params: {formValues: Partial<SelectCompaniesModalFormValues>; formName: string}) => void;
    getSimilarCompaniesDropdown: (params: {formValues: Partial<SelectCompaniesModalFormValues>}) => any;
    getInitialCompaniesList: () => void;
    getCompaniesBySorting: () => void;
    getCompaniesByFilters: (params: {
        filters: Partial<SelectCompaniesModalFormValues>;
        onPostSuccessFunc?: () => void;
    }) => void;
};

const useSelectCompaniesActions = (): UseSelectCompaniesActions => {
    const dispatch = useDispatch();

    return {
        getSimilarCompaniesDropdown: (params) => dispatch(selectCompaniesActions.getSimilarCompaniesDropdown(params)),
        saveSelectedCompanies: (params) => dispatch(selectCompaniesActions.saveSelectedCompanies(params)),
        getCompaniesByFilters: (params) => dispatch(selectCompaniesActions.getCompaniesByFilters(params)),
        getInitialCompaniesList: () => dispatch(selectCompaniesActions.getInitialCompaniesList()),
        getCompaniesBySorting: () => dispatch(selectCompaniesActions.getCompaniesBySorting()),
    };
};

export default useSelectCompaniesActions;
