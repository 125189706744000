import orderBy from 'lodash/orderBy';

export const handleReducerByWorkWithCompaniesByOrderByReceived = (params: {state}) => {
    const {state} = params;

    const currentOrderBy = state.workWithCompanies.orderBy;
    const {companies} = state.workWithCompanies;
    const {truck} = state.workWithCompanies;

    const orderMap = {
        null: 'asc',
        asc: 'desc',
        desc: null,
    };

    const nextSort = orderMap[currentOrderBy];

    const sortedCompanies = orderBy(companies, ['company_name'], [nextSort]);

    return {
        ...state,
        companies: nextSort ? sortedCompanies : truck?.companies,
        orderBy: nextSort,
    };
};
