import React from 'react';
import toLower from 'lodash/toLower';
import {Field} from 'redux-form';

import Carrier from 'core/entities/Carrier/types';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import useSelectCompaniesState from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {highlightSubstring} from './utils';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    isDisabledRow: boolean;
    company: Carrier;
    index: number;
};

const CompanyNameInfo: React.FC<OwnProps> = (props) => {
    const {company, isDisabledRow, index} = props;

    const {searchParams} = useSelectCompaniesState();

    const tooltipContentWhenCompanyIsNotAvailable = (
        <>
            The company is not available for activation. <br />
            To enable a company, add it to the owner page
        </>
    );

    const tooltipContentWhenTruckHasLoadWithCertainStatus = (
        <>
            You cannot turn off the company because <br /> the load is planned or in progress
        </>
    );

    return (
        <div>
            <Field
                label={highlightSubstring(toLower(company.company_name), toLower(searchParams?.companyName))}
                valueAs={{label: company.company_name, value: company.id}}
                name={`${getName('selectedCompanies')}[${index}]`}
                component={CheckBoxInput}
                disabled={isDisabledRow}
                position="right"
            />

            <div className={styles.badge}>
                <TooltipBootstrap tooltipContent={tooltipContentWhenTruckHasLoadWithCertainStatus}>
                    <i className="fa fa-exclamation-triangle" />
                </TooltipBootstrap>
            </div>
        </div>
    );
};

export default CompanyNameInfo;
