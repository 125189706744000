import React from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {setSortBy} from 'store/actionCreators';
import {SortProps} from 'store/reducers/sort/types';

import useSortingState from 'hooks/useSortingState';

import styles from './styles.module.scss';

type OwnProps = {
    onClick?: () => void;

    sortType: 'numeric' | 'alpha';
    title: React.ReactNode;
};

const SortingIcon: React.FC<OwnProps & SortProps> = (props) => {
    const {sortBy, sortType, title, sortListName, onClick} = props;

    const {currentSortFrom, currentSortBy} = useSortingState(sortListName);
    const dispatch = useDispatch();

    const handleChangeSort = () => {
        dispatch(setSortBy({sortBy, sortListName}));

        if (onClick) {
            onClick();
        }
    };

    const sortFrom = sortBy === currentSortBy ? currentSortFrom : undefined;

    const iconClassName = classNames('fa', {
        [`fa-sort-${sortType}-asc`]: !sortFrom || sortFrom === 'asc',
        [`fa-sort-${sortType}-desc`]: sortFrom === 'desc',
    });

    return (
        <span onClick={handleChangeSort} className={classNames(styles.icon, {[styles.applied]: sortFrom})}>
            {title} <i className={iconClassName} />
        </span>
    );
};

export default SortingIcon;
