import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {combineReducers} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {USER_LOGOUT} from 'store/actionTypes';

import config from 'config';

import chat from 'widgets/Chat/redux/reducers';
import notifications from 'widgets/Notifications/redux/reducers';

import truckReserveModal from 'components/common/Truck/modals/TruckReserving/reducer';
import modals from 'components/ui/ModalProvider/reducer';

import carriers from 'pages/Carriers/reducers/index';
import customers from 'pages/Customers/redux/reducers';
import dispatchers from 'pages/Dispatchers/reducers/index';
import drivers from 'pages/Drivers/reducers/index';
import facilities from 'pages/Facilities/redux/reducers';
import factoringCompanies from 'pages/FactoringCompanies/redux/reducers';
import fullAlliance from 'pages/FullAlliance/redux/reducers';
import invoices from 'pages/Invoices/reducers/index';
import loadBoard from 'pages/LoadBoard/redux/reducers/index';
import loads from 'pages/Loads/redux/reducers';
import login from 'pages/Login/Dispatchland/reducer';
import logs from 'pages/Logs/reducers';
import myCompany from 'pages/MyCompany/redux/reducers';
import owners from 'pages/Owners/redux/reducers';
import settlements from 'pages/Settlements/reducers/index';
import tripMonitor from 'pages/TravelOrders/redux/reducers';
import trucks from 'pages/Trucks/redux/reducers';
import trucksMap from 'pages/TrucksMap/reducer';
import trucksNearby from 'pages/TrucksNearby/redux/reducers';

import appAdminSettings from './adminSettings';
import appSettings from './appSettings';
import auth from './auth';
import appCelebration from './celebration';
import app from './commonData';
import gallery from './gallery';
import permissions from './permissions';
import registry from './registry';
import sessionData from './sessionData';
import sort from './sort';
import appUserData from './userData';
import appUserPermissions from './userPermissions';

const history = createBrowserHistory();

const appReducer = combineReducers({
    auth,
    sort,
    app,

    // pages
    loads,
    invoices,
    settlements,
    trucks,
    tripMonitor,
    owners,
    drivers,
    trucksMap,
    login,
    trucksNearby,
    fullAlliance,
    carriers,
    facilities,
    factoringCompanies,
    logs,
    dispatchers,
    customers,
    loadBoard,
    myCompany,
    modals,
    truckReserveModal,
    sessionData,

    // ui-components
    chat,
    notifications,
    gallery,

    // data
    userData: appUserData,
    userPermissions: appUserPermissions,
    permissions,
    appSettings,
    adminSettings: appAdminSettings,
    celebration: appCelebration,
    registry,

    // plugins
    form: reduxFormReducer,
    router: connectRouter(history),
});

const rootReducer = (state, action): ReturnType<typeof appReducer> =>
    appReducer(action.type !== USER_LOGOUT ? state : undefined, action);

const newAppBuildId: string = config.BUILD_HASH;

const persistedReducer = persistReducer(
    {
        key: `appStore${newAppBuildId}`,
        storage,
        whitelist: ['auth', 'userPermissions', 'adminSettings', 'permissions', 'appSettings', 'userData', 'registry'],
    },
    rootReducer,
);

export default persistedReducer;
