import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';

import Truck from 'core/entities/Truck/types';

import useModalActions from 'pages/Owners/hooks/useModalActions';

import WorkWithCompaniesInfo from './components/WorkWithCompaniesInfo';
import useTruckWorkWithCompaniesActions from './hooks/useTruckWorkWithCompaniesActions';

type OwnProps = {
    data: {truck: Truck};
};

const WorkWithCompanies: React.FC<OwnProps> = (props) => {
    const {
        data: {truck},
    } = props;

    const {initWorkWithCompanies, clearWorkWithCompaniesState} = useTruckWorkWithCompaniesActions();
    const {closeModal} = useModalActions();

    useEffect(() => {
        initWorkWithCompanies({truck});

        return () => {
            clearWorkWithCompaniesState();
        };
    }, []);

    return (
        <Modal show={true} onHide={closeModal} dialogClassName="standard-form-modal modal-lg" backdrop="static">
            <Modal.Header className="modal-header__top">
                <Modal.Title className="font-weight-bold">Truck work with companies</Modal.Title>

                <button onClick={closeModal} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <WorkWithCompaniesInfo truck={truck} />
            </Modal.Body>
        </Modal>
    );
};

export default WorkWithCompanies;
