import {AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios';
import isEmpty from 'lodash/isEmpty';

import Driver from 'core/entities/Driver/types';

import restapi, {fetchFiles} from 'services/restapi';
import {getGeneralNotes, postGeneralNote} from 'services/restapi/generalNotes';

import {ENTITY_NAME_DRIVERS} from 'utils/data/entityNames';
import groupFilesByType from 'utils/files/groupFilesByType';

import StoredFile, {GroupedFiles} from 'types/File';
import GeneralNote from 'types/GeneralNote';

const apiUrl = `/${ENTITY_NAME_DRIVERS}`;

export const searchDrivers = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/search`, params);
};

export const fetchDriversAvatars = (): Promise<AxiosResponse> => {
    return restapi.get('/files/drivers/avatar');
};

export const fetchDriversList = (params): Promise<AxiosResponse> => {
    return restapi.get(apiUrl, {params});
};

export const fetchDriver = (driverID: number): Promise<AxiosResponse<Driver>> => {
    return restapi.get(`${apiUrl}/${driverID}`);
};

export const updateDriver = (driver: Driver): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${driver.id}`, driver);
};

export const createDriver = (driver: Driver): Promise<AxiosResponse> => {
    return restapi.post(apiUrl, driver);
};

export const archiveDriver = (driver: Driver): Promise<Driver> => {
    return restapi.put(`${apiUrl}/${driver.id}/archive`).then(() => ({...driver, is_deleted: true}));
};

export const restoreDriver = (driver: Driver): Promise<Driver> => {
    return restapi.put(`${apiUrl}/${driver.id}/restore`).then(() => ({...driver, is_deleted: false}));
};

export const searchDriversByTitle = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/title/search`, params);
};

export const searchDriverDuplicates = (driver: Driver): Promise<Driver | undefined> => {
    const {mobile_phone, full_name} = driver;

    return searchDrivers({mobile_phone, full_name}).then((response): Driver | undefined => {
        const {data: drivers} = response;
        if (isEmpty(drivers)) {
            return;
        }

        return drivers.find((item: Driver) => {
            return item.mobile_phone === mobile_phone && item.full_name.toLowerCase() === full_name.toLowerCase();
        });
    });
};

export const getDriverById = (driverId: number): Promise<AxiosResponse> => {
    return restapi(`${apiUrl}/${driverId}`);
};

export const fetchNetworksBenefitsList = (): Promise<AxiosResponse> => {
    return restapi(`${apiUrl}/benefits`);
};

export const updateDriverPassword = (
    driverId: number,
    data: {email: string; new_password: string; confirm_password: string},
): AxiosPromise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${driverId}/password`, data);
};

export const restoreDriverAccount = (driverId: number): AxiosPromise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${driverId}/activate-account`);
};

export const getDriverFilesById = (id: number): Promise<StoredFile[]> => {
    const params = {
        id,
        entity: ENTITY_NAME_DRIVERS,
    };
    return fetchFiles(params).then((res) => {
        if ('data' in res) {
            return res.data;
        }
    });
};

export const getGroupedDriverFiles = (id: number): Promise<GroupedFiles> => {
    return getDriverFilesById(id).then((files: StoredFile[]): GroupedFiles => groupFilesByType(files));
};

export const getDriverGeneralNotes = (driverId: number): Promise<GeneralNote[]> => {
    return getGeneralNotes({entity_name: ENTITY_NAME_DRIVERS, entity_id: driverId}).then(({data}) => data);
};

export const postDriverGeneralNote = (driverID: number, text: string): Promise<GeneralNote> => {
    return postGeneralNote({entity_name: ENTITY_NAME_DRIVERS, entity_id: driverID, text}).then(({data}) => data);
};

type DriverHiddenData = {
    mobile_phone: string;
    email: string;
    id: number;
};

export const revealDriverHiddenData = (
    {driverID},
    config?: AxiosRequestConfig,
): Promise<AxiosPromise<DriverHiddenData[]>> => {
    return restapi.get(`${apiUrl}/${driverID}/hidden-data`, config);
};
