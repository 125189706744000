import orderBy from 'lodash/orderBy';

import Owner from 'core/entities/Owner/types';

import Pagination from 'types/Pagination';

const ORDER_MAP = {
    desc: 'desc',
    asc: 'asc',
    null: null,
};

const getPaginatedCompaniesTabItems = (params: {state; pagination: Pagination}) => {
    const {state, pagination} = params;

    const startIndex = (pagination.currentPage - 1) * pagination.perPage;
    const endIndex = startIndex + pagination.perPage;

    return (state.truckData?.companies || []).slice(startIndex, endIndex);
};

const orderByCompaniesTabItems = (params: {state; items}) => {
    const {state, items} = params;

    const currentOrderBy = state.companiesTab.orderBy;

    const nextSort = ORDER_MAP[currentOrderBy];

    return orderBy(items, ['company_name'], [nextSort]);
};

export const handleReducerByOwnerReceived = (params: {state; owner: Owner}) => {
    const {state, owner} = params;

    const {companies} = state.truckData || [];
    const {pagination} = state.companiesTab;

    const companiesTab = {
        ...state.companiesTab,
        items: getPaginatedCompaniesTabItems({state, pagination}),
        pagination: {
            totalItemsCount: companies?.length,
            currentPage: pagination.currentPage,
            pagesCount: Math.ceil(companies?.length / pagination.perPage) || 1,
            perPage: pagination.perPage,
        },
    };

    return {
        ...state,
        data: owner,
        companiesTab,
    };
};

export const handleReducerByOwnerCompaniesTabItemsByPageReceived = (params: {state; followingPage: number}) => {
    const {state, followingPage} = params;

    const currentOrderBy = state.companiesTab.orderBy;

    const pagination = {
        ...state.companiesTab.pagination,
        currentPage: followingPage,
    };

    const paginatedItems = getPaginatedCompaniesTabItems({state, pagination});
    const sortedItems = orderByCompaniesTabItems({state, items: paginatedItems});

    const nextSort = ORDER_MAP[currentOrderBy];

    const companiesTab = {
        ...state.companiesTab,
        items: nextSort ? sortedItems : paginatedItems,
        pagination,
    };

    return {
        ...state,
        companiesTab,
    };
};

export const handleReducerByOwnerCompaniesTabItemsByPerPageReceived = (params: {state; perPage: number}) => {
    const {state, perPage} = params;

    const currentOrderBy = state.companiesTab.orderBy;
    const {companies} = state.truckData || [];

    const pagination = {
        ...state.companiesTab.pagination,
        pagesCount: Math.ceil(companies.length / perPage),
        currentPage: 1,
        perPage,
    };

    const paginatedItems = getPaginatedCompaniesTabItems({state, pagination});
    const sortedItems = orderByCompaniesTabItems({state, items: paginatedItems});

    const nextSort = ORDER_MAP[currentOrderBy];

    const companiesTab = {
        ...state.companiesTab,
        items: nextSort ? sortedItems : paginatedItems,
        pagination,
    };

    return {
        ...state,
        companiesTab,
    };
};

export const handleReducerByOwnerCompaniesTabItemsOrderByReceived = (params: {state}) => {
    const {state} = params;

    const currentOrderBy = state.companiesTab.orderBy;
    const {pagination} = state.companiesTab;
    const {items} = state.companiesTab;

    const orderMap = {
        null: 'asc',
        asc: 'desc',
        desc: null,
    };

    const nextSort = orderMap[currentOrderBy];

    const sortedItems = orderBy(items, ['company_name'], [nextSort]);

    return {
        ...state,
        companiesTab: {
            ...state.companiesTab,
            items: nextSort ? sortedItems : getPaginatedCompaniesTabItems({state, pagination}),
            orderBy: nextSort,
        },
    };
};
