import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import styles from './styles.module.scss';

type Filter = {
    onClear?: () => void;
    label: string;
    value: string;
};

type OwnProps = {
    clearFilters?: () => void;
    totalCount: number;
    filters: (Filter | null)[];
};

const AppliedFilters: React.FC<OwnProps> = (props) => {
    const {clearFilters, totalCount, filters} = props;

    const appliedFiltersCount = filters?.length || 0;

    const onClickClearAll = () => {
        if (clearFilters) {
            clearFilters();
        }
    };

    const filtersJSX = filters.map((filter, i) => {
        if (!filter) {
            return null;
        }

        const {label, value, onClear} = filter || {};

        return (
            <div className={styles.filter} key={i}>
                <div className={styles.label}>{label}:</div>
                <div className={styles.value}>{value}</div>
                {isFunction(onClear) ? (
                    <div className={styles.cross} onClick={() => onClear()}>
                        <i className="fa fa-times" aria-hidden="true" />
                    </div>
                ) : null}
            </div>
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div className={classNames(styles.icon, {[styles.some]: Boolean(appliedFiltersCount)})}>
                    <i className="fa fa-tags" aria-hidden="true" />
                </div>
                <div className={styles.text}>applied filters ({appliedFiltersCount}):</div>
            </div>

            <div className={styles.results}>{totalCount} results</div>

            {filtersJSX}

            {appliedFiltersCount && clearFilters ? (
                <div className={styles.clear} onClick={onClickClearAll}>
                    Clear all
                </div>
            ) : null}
        </div>
    );
};

export default AppliedFilters;
