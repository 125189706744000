import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import Select from 'components/ui/Form/Select';
import TextArea from 'components/ui/Form/TextArea';

import {validation} from 'pages/TrucksNearby/components/common/modals/SendQuote/validation';
import useModalActions from 'pages/TrucksNearby/hooks/useModalActions';

type OwnProps = {
    data: {label: string; value: string}[];
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, data, submitting} = props;

    const {closeModal} = useModalActions();

    return (
        <form onSubmit={handleSubmit}>
            <div className="p20 pb5">
                <Field
                    label={RequiredLabel('Send to')}
                    name="phoneListWithTruckNumbers"
                    placeholder="Send to"
                    component={Select}
                    isCreatable={true}
                    isMulti={true}
                    data={data}
                />

                <Field
                    placeholder="type a note about current driver"
                    label={RequiredLabel('Message')}
                    component={TextArea}
                    name="message"
                    type="text"
                    rows="20"
                />
            </div>

            <div className="modal-body__bottom">
                <Button onClick={closeModal}>Cancel</Button>

                <Button type="submit" colorTheme="blue" disabled={submitting}>
                    Send Quote
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({validate: validation})(Form);
