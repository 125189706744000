import SendQuote from './SendQuote';
import WorkWithCompanies from './WorkWithCompanies';

export const modalNames = {
    workWithCompanies: 'TNB/WORK_WITH_COMPANIES_MODAL',
    sendQuote: 'TNB/SEND_QUOTE_MODAL',
};

const modalMap = {
    [modalNames.workWithCompanies]: WorkWithCompanies,
    [modalNames.sendQuote]: SendQuote,
};

export default modalMap;
